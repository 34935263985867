/* Fonts Arial */

@font-face {
    font-family: "Arial-Rounded-MT";
    src: url("../../assets/fonts/Arial-Rounded-MT.ttf");
}

@font-face {
    font-family: "Arial-Rounded-MT-Bold";
    src: url("../../assets/fonts/Arial-Rounded-MT-Bold.ttf");
}

@font-face {
    font-family: "Arial-MT";
    src: url("../../assets/fonts/Arial-MT.ttf");
}

@font-face {
    font-family: "Arial-Bold-MT";
    src: url("../../assets/fonts/Arial-Bold-MT.ttf");
}

@font-face {
    font-family: "Arial-CE";
    src: url("../../assets/fonts/Arial-CE.ttf");
}

@font-face {
    font-family: "Arial-CE-Bold";
    src: url("../../assets/fonts/Arial-CE-Bold.ttf");
}

@font-face {
    font-family: "Arial-CE-Italic";
    src: url("../../assets/fonts/Arial-CE-Italic.ttf");
}

@font-face {
    font-family: "Arial-CE-Bold-Italic";
    src: url("../../assets/fonts/Arial-CE-Bold-Italic.ttf");
}

@font-face {
    font-family: "Arial-CE-MT-Black";
    src: url("../../assets/fonts/Arial-CE-MT-Black.ttf");
}

@font-face {
    font-family: "Nunito-Bold";
    src: url("../../assets/fonts/Nunito-Bold.ttf");
}


/* Fonts Aileron */

@font-face {
    font-family: "Aileron-Bold";
    src: url("../../assets/fonts/Aileron-Bold.otf");
}

@font-face {
    font-family: 'Aileron-Semi-Bold';
    src: url('../../assets/fonts/Aileron-Semi-Bold.otf');
}

@font-face {
    font-family: 'Aileron-Regular';
    src: url('../../assets/fonts/Aileron-Regular.otf');
}


/* Fonts Inter UI */

@font-face {
    font-family: 'InterUI';
    src: url('../../assets/fonts/InterUI-Regular.woff');
    font-weight: normal;
}

@font-face {
    font-family: 'InterUI';
    src: url('../../assets/fonts/InterUI-Medium.woff');
    font-weight: 500;
}

@font-face {
    font-family: 'InterUI';
    src: url('../../assets/fonts/InterUI-Bold.woff');
    font-weight: bold;
}

html body {
    font-family: 'InterUI', sans-serif;
}
