
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* Inter */

@font-face {
    font-family: 'Inter';
    src: url('./Inter-Light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Inter';
    src: url('./Inter-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Inter';
    src: url('./Inter-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Inter';
    src: url('./Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Inter';
    src: url('./Inter-Bold.ttf') format('truetype');
    font-weight: 700;
}

/* Nunito */

@font-face {
    font-family: 'Nunito';
    src: url('./Nunito-Light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Nunito';
    src: url('./Nunito-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Nunito';
    src: url('./Nunito-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Nunito';
    src: url('./Nunito-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Nunito';
    src: url('./Nunito-Bold.ttf') format('truetype');
    font-weight: 700;
}

/* Poppins */

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins-Light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins-Bold.ttf') format('truetype');
    font-weight: 700;
}

/* Days One */

@font-face {
    font-family: 'Days One';
    src: url('./DaysOne-Regular.ttf') format('truetype');
    font-weight: 400;
}

/* Train One */

@font-face {
    font-family: 'Train One';
    src: url('./TrainOne-Regular.ttf') format('truetype');
    font-weight: 400;
}

/* Roboto */

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
}

/* Quicksand */

@font-face {
    font-family: 'Quicksand';
    src: url('./Quicksand-Light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Quicksand';
    src: url('./Quicksand-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Quicksand';
    src: url('./Quicksand-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Quicksand';
    src: url('./Quicksand-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Quicksand';
    src: url('./Quicksand-Bold.ttf') format('truetype');
    font-weight: 700;
}