/*Import utils*/
@import "utils/variables";
@import "utils/icons";
@import "utils/helpers";

/*Import vendors and base*/
@import "base/reset";
@import "base/typography";

/*Import layout*/
@import "layout/main";
@import "layout/titles.scss";

/*Import components*/
@import "components/list-orders";
@import "components/item-order";
@import "components/menu-tab";
@import "components/select";
@import "components/buttons";
@import "components/checkbox";
@import "components/forms";
