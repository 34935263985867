.list-orders {
    margin-right: 12px;
    &__title {
        font-size: 1.125rem;
        color: $color-dark-gray;
        margin-bottom: 19px;
        font-weight: 500;
        padding-top: 8px;
    }
    &__header {
        width: 320px;
        background-color: $background-gray;
        border-radius: 8px 8px 0 0;
        padding: 13px 10px 10px 10px;
    }
    &__items {
        width: 320px;
        height: calc(100vh - 246px);
        background-color: $background-gray;
        border-radius: 0 0 8px 8px;
        @include breakpoint('tablet') {
            height: calc(100vh - 280px);
        }
    }
    &__container {
        display: flex;
        flex-direction: column;
        gap: 15px;
        background-color: $background-gray;
        border-radius: 0 0 8px 8px;
        padding: 0 10px 10px 10px;
    }
    &__body {
        display: flex;
        width: 336px;
        height: 100%;
        overflow-y: hidden;
        border-radius: 0 0 8px 8px;
    }
    .ps__rail-y {
        opacity: 0.6;
    }
}
