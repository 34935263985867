.time-icon {
  background-image: url("/assets/img/dashboard/time.svg");
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  display: inline-block;
}

.credit-card-icon {
  background-image: url("/assets/img/dashboard/credit-card.svg");
  background-repeat: no-repeat;
  width: 19.2px;
  height: 14.5px;
  display: inline-block;
}

.cash-icon {
  background-image: url("/assets/img/dashboard/cash.svg");
  background-repeat: no-repeat;
  width: 19.2px;
  height: 11px;
  display: inline-block;
}

.map-icon {
  background-image: url("/assets/img/dashboard/map.svg");
  background-repeat: no-repeat;
  width: 13.3px;
  height: 17.1px;
  display: inline-block;
}

.back-icon {
  background-image: url("/assets/img/dashboard/back.svg");
  background-repeat: no-repeat;
  width: 30px;
  height: 14px;
  display: inline-block;
  position: relative;
  top: 3px;
}
