.checkbox[type="checkbox"] {
	&:checked, &:not(:checked){
		position: absolute;
		left: -9999px;
		+ label{
			&:before{
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 22px;
				height: 22px;
				border: 1px solid #979797;
				-webkit-appearance: none;
				background: #fff;
				border-radius: 2px;
			}
			&:after{
				content: '';
				display: block;
				position: absolute;
				top: 3px;
				left: 8px;
				width: 6px;
				height: 12px;
				border: solid #ffffff;
				border-width: 0 2px 2px 0;
				transform: rotate(45deg) !important;
				-webkit-transition: all 0.2s ease;
				transition: all 0.2s ease;
			}
		}
	}
	&:checked{
		+ label{
			position: relative;
			padding-left: 31px;
			cursor: pointer;
			display: inline-block;
			color: #292f31;
			font-weight: 500;
			font-size: 16px;
			top: -17px;
			&:before{
				border: 2px solid #05cb95;
				background: #05cb95;
			}
			&:after{
				opacity: 1;
				-webkit-transform: scale(1);
				transform: scale(1);
			}
		}
	}
	&:not(:checked){
		+ label{
			position: relative;
			padding-left: 31px;
			cursor: pointer;
			display: inline-block;
			color: #292f31;
			font-weight: 500;
			font-size: 16px;
			top: -17px;
			&:after{
				opacity: 0;
				-webkit-transform: scale(0);
				transform: scale(0);
			}
		}
	}
}
