@mixin limitRow($amout) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $amout; /* number of lines to show */
            line-clamp: $amout; 
    -webkit-box-orient: vertical;
}
@mixin breakpoint($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media (max-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    }
}