.item-order {
    padding: 18px;
    width: 299px;
    height: 200px;
    border-radius: 6px;
    background-color: $white-color;
    &__list-info {
        display: grid;
        grid-template-rows: min-content 42px auto min-content;
        grid-template-columns: auto 1fr 80px;
        grid-template-areas:
            "order-id total-items time"
            "client-name client-name type"
            "address address address"
            "price details details";
        height: 100%;
        gap: 4px;
    }
    &__id {
        grid-area: order-id;
        color: $color-gray-medium;
        font-size: .938rem;
    }
    &__total-items {
        grid-area: total-items;
        justify-self: end;
        color: $primary-color;
        font-size: .875rem;
    }
    &__time {
        grid-area: time;
        justify-self: end;
        color: $color-gray-light;
        font-size: .875rem;
        i {
            margin-right: 7px;
            vertical-align: text-top;
        }
    }
    &__client-name {
        grid-area: client-name;
        color: $color-dark-gray;
        font-weight: 500;
        line-height: 20px;
        @include limitRow(2);
    }
    &__type {
        grid-area: type;
        justify-self: end;
        color: $color-dark-gray;
        font-size: .875rem;
        font-weight: 500;
    }
    &__address {
        display: flex;
        grid-area: address;
        color: $color-gray-light;
        font-size: .875rem;

        span {
            @include limitRow(3);
        }
        i {
            margin-top: 4px;
            flex-basis: 24px;
            flex-shrink: 0;
            flex-grow: 0;
        }
    }
    &__price {
        grid-area: price;
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: .875rem;
        color: $color-soft-dark;
        i {
            margin-right: 7px;
            vertical-align: text-top;
        }
    }
    &__details {
        grid-area: details;
        justify-self: end;
        a {
            color: $primary-color;
            font-size: .875rem;
        }
    }
}

@media(max-width: 350px){
    .item-order{
        width: 270px;
    }
}
