.menu-tab {
    height: 38px;
    border-bottom: 1px solid $background-gray;
    vertical-align: text-top;
    display: flex;
    gap: 20px;
    margin-bottom: 28px;
    &__item {
        position: relative;
        cursor: pointer;
        color: $color-gray-medium;
    }
    &__name {
        color: inherit;
        font-size: 1.125rem;
    }
    &__bar {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        border-radius: 3px 3px 0 0;
        background-color: $primary-color;
    }
    &--active {
        color: $primary-color;
        .menu-tab__bar {
            display: block;
        }
    }
}