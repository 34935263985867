$white-color: #fff;
$background-gray: #e8e8e8;
$color-dark-gray: #575757;
$color-gray-medium: #666666;
$color-gray-light: #979797;
$primary-color: #05db95;
$color-soft-dark: #292f31;

/*Add breakpoints*/
$breakpoints: (
    'mobile': 576px,
    'tablet': 768px,
    'desktop': 992px
) !default;
