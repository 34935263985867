@import "~ngx-toastr/toastr";
@import './assets/fonts/fonts.scss';
@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/ngx-bootstrap/datepicker/bs-datepicker";

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Train+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Days+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* Default CSS */

body.jvadd{
  jdiv{
    display: none !important;
  }
}

jdiv{
  .button_a883 {
    background: #05cb95 !important;
  }
}

._orientationRight_e3a1 .button_f9cf{
  margin-right: 15px !important;
}
input[type="button"]:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

input[type="subimit"]:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

button:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

::selection {
  background-color: #05cb95;
  color: #fff;
}

::-webkit-scrollbar {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #f0f0f0;
}

@media(max-width: 768px){
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.bs-datepicker {
  font-family: "InterUI", sans-serif !important;
  border-radius: 16px !important;
}

bs-calendar-layout {
  border-radius: 14px !important;
}

.bs-datepicker-head {
  border-radius: 14px 14px 0px 0px !important;
}

.bs-datepicker-body {
  border: 2px solid #05cb95;
  border-radius: 0px 0px 14px 14px !important;
}

.bs-datepicker-container {
  border-radius: 16px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
}

.theme-green .bs-datepicker-head {
  background-color: #05cb95;
}

.bs-datepicker-body table td {
  color: #05cb95;
}

.bs-datepicker-body table td span.selected {
  background-color: #05cb95 !important;
}

.bs-datepicker-body table td.week span {
  color: #292f31 !important;
}

.box__upload__container{
  .box-document-end{
    height: 295px !important;
  }
  .empty-imagem{
    margin-top: 63px !important;
  }
  .header{
    &__itens{
      &.one{
        display: none !important;
      }
      &.two{
        display: none !important;
      }
    }
  }
  .subtitle{
    max-width: unset !important;
  }
}

body,
html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body.hfon{
  app-header{
    display: none !important;
  }
  app-footer{
    display: none !important;
  }
}

body.open{
  position: fixed;
  .container__logo--mobile{
    left: 0;
    right: 0;
    margin: 0 auto;
    svg{
      margin: 0 auto;
      animation: menuAnimationIn .2s;
      transition: all .2s;
    }
  }
}

body.openmenumobile.onopen{
  overflow-y: hidden;
  .container__logo--mobile{
    left: 0;
    right: 0;
    margin: 0 auto;
    svg{
      margin: 0 auto;
      animation: menuAnimationIn .2s;
      transition: all .2s;
    }
  }
}

body.onopen{
  .container__logo--mobile{
    left: 0;
    right: 0;
    margin: 0 auto;
    svg{
      margin: 0 0 0 12px;
      animation: menuAnimationOut .2s;
      transition: all .2s;
    }
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes menuAnimationIn{
  0%{
    margin: 0 0 0 12px;
  }
  80%{
    margin: 0 0 0 46%;
  }
  100%{
    margin: 0 auto;
  }
}

@keyframes menuAnimationOut{
  0%{
    margin: 0 auto;
  }
  50%{
    margin: 0 0 0 46%;
  }
  100%{
    margin: 0 0 0 12px;
  }
}

@keyframes buttonAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

body {
  &.modal-open {
    padding: 0 17px 0 0 !important;
  }
}

@media (max-width: 834px) {
  body.modal-open {
    padding-right: unset !important;
  }
}

.group-select input:read-only {
  background-color: transparent !important;
  cursor: pointer !important;
}

.modal-open .modal::-webkit-scrollbar {
  width: 0%;
}

.dropdown-menu::-webkit-scrollbar {
  width: 0px !important;
}

.primary-color {
  background-color: #05cb95 !important;
}

.modal-dialog.warning-modal {
  max-width: unset !important;
}

.modal-dialog.warning-modal {
  max-width: unset !important;
}

/*Toast style*/

.toast-error {
  background-color: red;
}

.toast-success {
  background-color: #05cb95;
}

.toast-warning {
  background-color: #05cb95;
}

.toast-message {
  color: white;
}

.text-default {
  color: #05cb95;
}

.border-green {
  border: 1px solid #05cb95 !important;
  outline: 1px solid #05cb95;
}

.text-default-add,
.text-default-add:hover {
  color: #05cb95;
  text-decoration: underline;
  font-size: 16px;
}

.modal {
  .modal-header {
    border: none;
  }
  .buttons-modal-m {
    position: relative;
    box-shadow: none;
    bottom: 35px;
  }
}

.boxs-upload {
  .box {
    .pdfViewer {
      .page {
        max-height: 182px !important;
        overflow: hidden !important;
      }
    }
    &.box-document-end {
      .pdfViewer {
        .page {
          max-height: 280px !important;
          overflow: hidden !important;
        }
      }
    }
  }
}

.form-control,
.input100 {
  &.invalid {
    box-shadow: none;
  }
}

.invalid-text-feedback {
  font-size: 13px;
  color: #d61d30 !important;
  margin-top: 0px;
  padding: 0px 0px 0px 5px;
  position: absolute;
  white-space: nowrap;
  img {
    margin-right: 5px;
  }
  span {
    position: relative;
    color: #d61d30 !important;
    top: 2px;
  }
}

.row-group {
  display: flex;
}

#street {
  width: 81%;
  margin-right: 20px;
}

.tooltip-inner {
  background-color: #292f31 !important;
  padding: 10px 15px !important;
  border-radius: 8px !important;
  color: #FFF !important;
  font-family: 'Roboto', sans-serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  z-index: 1 !important;
}

.tooltip.top .tooltip-arrow:before,
.tooltip.top .tooltip-arrow {
  border-top-color: #292f31 !important;
}

.dropdown {
  width: 385px;
  border: 2px solid #05cb95;
  border-radius: 8px;
  padding: 0px;
  z-index: 3 !important;
  &-menu{
    min-width: 0px !important;
  }
  &.open {
    .dropdown-item div p {
      margin: 0 auto 0 0;
      font-size: 16px !important;
      font-weight: 500 !important;
    }
    .dropdown-item {
      color: #575757;
      padding: 8px 18px 8px 18px;
    }
    .dropdown-item.active,
    .dropdown-item:active {
      background-color: #effbf7;
      color: #05cb95;
    }
  }
}

.dropdown.open {
  width: 100%;
}

@media (max-width: 480px) {
  .dropdown {
    width: 295px;
  }
}

@media (max-width: 320px) {
  .dropdown {
    width: 281px;
  }
}

/* Content Dashboard (private) */

.wrap-container {
  margin-bottom: 100px;
  margin-top: 30px;
  .links {
    padding-right: 10px;
    display: flex;
    a {
      &:first-child {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #05cb95;
        margin-right: 47px;
        display: flex;
        align-items: center;
      }
      &:last-child {
        font-size: 16px;
        color: #05cb95;
        border: 2px solid #05cb95;
        border-radius: 30px;
        text-decoration: none;
        padding: 12px 40px;
      }
    }
  }
  .item-body {
    .search {
      margin-top: 40px;
      .input-group-text {
        font-size: 16px;
        color: #808285;
        font-weight: 500;
        background-color: #efefef;
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
    summary input {
      &:focus {
        border: none !important;
      }
    }
    input,
    select {
      height: 45px;
      &:focus {
        box-shadow: none;
        border: 2px solid #05cb95;
        color: #05cb95;
      }
    }
    .filters {
      border: 1px solid #d5d5d5;
      border-radius: 16px;
      margin-top: 14px;
      .plain-select {
        position: relative;
        display: block;
        width: 100%;
        &:after {
          content: url("assets/img/dashboard/select.svg");
          position: absolute;
          z-index: 2;
          right: 15px;
          top: 10px;
          right: 7px;
          border-radius: 5px;
          z-index: 0;
        }
      }
      label {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #292f31;
      }
      select {
        appearance: none;
        position: relative;
        z-index: 1;
        color: #aaaaaa;
        height: 45px;
        background: transparent;
      }
      .btn-filter {
        width: 100%;
        height: 45px;
        background: #05cb95;
        color: #ffffff;
        box-shadow: none;
        border-radius: 30px;
        margin-top: 32px;
        font-size: 16px;
        float: right;
        font-weight: 500;
        &:hover {
          background: #0aa77c;
        }
        &.clear {
          background: #292f31;
          color: #fff;
          &:hover {
            background-color: #000000;
          }
        }
      }
    }
  }
  .wrap-items {
    margin-top: 30px;
    .nav-item {
      &.ckeckbox {
        [type="checkbox"] {
          &:checked,
          &:not(:checked) {
            position: absolute;
            left: -9999px;
            + label {
              &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 22px;
                height: 22px;
                border: 1px solid #d5d5d5;
                appearance: none;
                background: #fff;
                border-radius: 6px;
              }
              &:after {
                content: "";
                display: block;
                position: absolute;
                top: 3px;
                left: 8px;
                width: 6px;
                height: 12px;
                border: solid #ffffff;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg) !important;
                -webkit-transition: all 0.2s ease;
                transition: all 0.2s ease;
              }
            }
          }
          &:checked {
            + label {
              position: relative;
              padding-left: 20px;
              cursor: pointer;
              display: inline-block;
              color: #05cb95;
              font-size: 16px;
              top: -17px;
              &:before {
                border: 2px solid #05cb95;
                background: #05cb95;
              }
              &:after {
                opacity: 1;
                -webkit-transform: scale(1);
                transform: scale(1);
              }
            }
          }
          &:not(:checked) {
            + label {
              position: relative;
              padding-left: 20px;
              cursor: pointer;
              display: inline-block;
              color: #979797;
              font-size: 16px;

              top: -17px;
              &:after {
                opacity: 0;
                -webkit-transform: scale(0);
                transform: scale(0);
              }
            }
          }
        }
      }
    }
  }
  .nav-header-items {
    padding: 20px 20px 0;
    font-size: 15px;
    color: #292f31;
    font-weight: 500;
    li {
      font-weight: 500;
      color: #292f31;
      font-size: 16px;
      &:nth-child(2) {
        span + span {
          display: none;
        }
      }
    }
  }
  li {
    &:nth-child(2) {
      width: 45%;
    }
    &:nth-child(3) {
      width: 18%;
    }
    &:nth-child(4) {
      width: 25%;
    }
    &:nth-child(5) {
      width: 8%;
      text-align: start;
    }
    @media (max-width: 780px) {
      &:nth-child(4) {
        width: 22%;
      }
      &:nth-child(5) {
        width: 10%;
      }
    }
    @media (max-width: 700px) {
      &:nth-child(3) {
        width: 16%;
      }
      &:nth-child(4) {
        width: 20%;
      }
      &:nth-child(5) {
        width: 13%;
      }
    }
  }
  .nav-body-items {
    align-items: center;
    border: 1px solid #d5d5d5;
    border-radius: 16px;
    margin-top: 20px;
    padding: 21px 50px 20px 20px;
    &.disabled {
      background: #f5f5f5;
    }
    a {
      cursor: pointer;
    }
    .nav-item {
      img {
        width: 74px;
        min-width: 74px;
        height: 54px;
        min-height: 54px;
        margin-right: 11px;
        margin-left: 5px;
        object-fit: contain;
        border-radius: 8px;
      }
      .texts {
        width: 100%;
        p {
          margin: 0;
          font-size: 16px;
          color: #292f31;
          line-height: 22px;
          width: 90% !important;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: pre-wrap;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        span {
          font-size: 12px;
          color: #979797;
        }
      }
      .item-price-qtd {
        p {
          margin: 0;
          font-size: 16px;
          color: #808285;
          line-height: 15px;
        }
        span {
          position: relative;
          font-weight: 500;
          font-size: 13px;
          color: #292f31;
          text-decoration: underline;
          cursor: pointer;
          &.clicked {
            .arrow-icon {
              transform: rotate(180deg);
            }
          }
        }
      }
      &.status {
        input[type="checkbox"] {
          appearance: none;
          width: 45px;
          height: 10px;
          background: #a7a8a8;
          border-radius: 5px;
          position: relative;
          outline: 0;
          cursor: pointer;
          transition: all 0.25s;
          &:focus {
            border: none;
          }
          &:checked {
            background: #0aa77c;
            &:before {
              left: calc(100% - 25px);
              background: #05cb95;
            }
            &:after {
              left: 75px;
              transform: scale(0);
            }
            + .form-check-label {
              color: #05cb95;
            }
          }
          &:before,
          &:after {
            position: absolute;
            content: "";
            transition: all 0.25s;
          }
          &:before {
            width: 25px;
            height: 25px;
            background: #c5c5c5;
            border-radius: 50%;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          }
        }
      }
    }
    &.custom-item {
      border: 2px solid #05cb95 !important;
      border-bottom: none !important;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      margin-bottom: 0 !important;
    }
  }
  .wrap-subitems {
    padding: 20px 25px;
    border: 1px solid #e1e1e1;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    &.custom-border {
      border-left: 2px solid #05cb95 !important;
      border-bottom: 2px solid #05cb95 !important;
      border-right: 2px solid #05cb95 !important;
    }
    .item-titles {
      display: flex;
      align-items: center;
      p {
        font-size: 16px;
        color: #292f31;
        margin-bottom: 5px;
        &:first-child {
          width: 52%;
        }
      }
    }
    .list-subitems {
      padding: 10px 0;
      border-bottom: 1px solid #e1e1e1;
      &:last-child {
        border-bottom: none;
      }
      .item-image-description {
        width: 57%;
        padding-right: 140px;
        img {
          width: 62px;
          height: 43px;
          margin-right: 15px;
          object-fit: contain;
        }
      }
      .item-price {
        width: 49%;
      }
      .item-price .price p,
      .item-image-description p {
        margin: 0;
        font-size: 16px;
        color: #808285;
      }
    }
  }
}
@media (max-width: 360px) {
  .wrap-container .nav-body-items .nav-item .texts p {
    max-width: 15ch !important;
  }
}

/* Navbar Dashboard */

.navbar {
  &.navbar-dashboard {
    .notifications {
      position: relative;
      width: 30px;
      margin-right: 21.25px;
      img {
        cursor: pointer;
      }
      span {
        background-color: #d61d30;
        border-radius: 50%;
        position: absolute;
        right: 13px;
        top: 10px;
        width: 10px;
        height: 10px;
        z-index: 2;
      }
    }
    .icon-home {
      margin-right: 25px;
    }
    .status-checked {
      margin-right: 45px;
      display: flex;
      span {
        margin-left: 50px;
      }
      button {
        background-color: #16a880;
        outline: none;
        box-shadow: none;
        font-size: 16px;
        color: #ffffff;
        padding-left: 20px;
        &.closed {
          background: #484b4a;
        }
      }
      .dropdown-toggle {
        &:after {
          content: url("assets/img/dashboard/select-white.svg");
          border: none;
          position: absolute;
          right: 10px;
        }
      }
      .dropdown-menu {
        width: 100%;
        .dropdown-item {
          padding: 0 0 0 20px;
          color: #484b4a;
          &:hover {
            background-color: #ffffff;
          }
        }
      }
    }
    .profile-menu {
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      .username {
        font-size: 16px;
        color: #7d7a7a;
        margin-left: 7px;
      }
      .profile-img {
        width: 25px;
        height: 25px;
        padding: 0;
        border-radius: 30px;
        border: none;
        outline: none;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .button-drop {
        display: flex;
        align-items: center;
      }
      .dropdown-menu {
        width: 100%;
        padding: 0px;
        border: none;
        box-shadow: 0px 7px 20px -2px rgba(0, 0, 0, 0.16);
        top: 51px !important;
        left: -77px !important;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        cursor: default;
        .menuitem {
          padding: 20px;
          p {
            font-size: 20px;
            font-weight: 500;
            color: #2b2b2b;
          }
          + .menuitem {
            background: #e6e5f5;
            padding-top: 25px;
          }
          a {
            color: #575757;
            text-decoration: none;
            display: block;
            width: 100%;
            span {
              font-size: 14px;
              margin-left: 10px;
            }
            &:hover {
              span {
                color: #6c63ff;
              }
            }
          }
        }
      }
    }
  }
}

#modalConfirm {
  .modal-dialog {
    margin-top: 20vh;
  }
  .modal-header {
    border: none;
    .close {
      margin-right: 0;
      outline: none;
    }
  }
  .modal-body {
    padding-top: 0;
    h4 {
      font-size: 24px;
      color: #484b4a;
      font-family: "Aileron-Bold";
      margin-bottom: 20px;
    }
    p {
      font-size: 16px;
      color: #606060;
      margin: 0 auto 30px;
      width: 80%;
    }
    .modal-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 20px 5px;
      button {
        &:first-child {
          background: #05cb95;
          border-radius: 3px;
          border: none;
          outline: none;
          width: 182px;
          color: #ffffff;
          font-size: 16px;
          padding: 11px 0;
        }
        &:last-child {
          background: #ffffff;
          border-radius: 3px;
          border: 1px solid #dddddd;
          outline: none;
          width: 118px;
          color: #979797;
          font-size: 16px;
          padding: 11px 0;
        }
        &.close-confirm {
          &:first-child {
            background: #484b4a;
          }
        }
      }
    }
  }
}

#modalNotifications {
  .modal-dialog {
    right: 148px;
    top: 31px;
    position: absolute;
  }
  .modal-content {
    width: 308px;
  }
  .modal-header {
    border: none;
    .close {
      outline: none;
      img {
        width: 15px;
      }
    }
    h5 {
      font-size: 24px;
      font-family: "Aileron-Bold";
      color: #484b4a;
    }
    span {
      width: 33px;
      height: 33px;
      background-color: #05cb95;
      color: #ffffff;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 15px;
    }
  }
  .modal-body {
    padding-bottom: 0;
    .nav {
      margin-right: -16px;
      margin-left: -16px;
      .nav-item {
        border-bottom: 1px solid #e1e1e1;
        &:last-child {
          border-bottom: none;
        }
        .nav-link {
          color: #979797;
          font-size: 18px;
          padding: 12px;
          &:hover {
            color: #05cb95;
            &:before {
              content: "";
              background-color: #05cb95;
              width: 4px;
              height: 24px;
              display: block;
              position: absolute;
              left: 0;
            }
          }
        }
      }
    }
  }
}

#modalProfile {
  .modal-dialog {
    right: 45px;
    top: 31px;
    position: absolute;
  }
  .modal-content {
    width: 288px;
  }
  .modal-body {
    .nav {
      .nav-item {
        .nav-link {
          display: flex;
          align-items: center;
          padding-left: 17px;
          i {
            margin-right: 10px;
          }
        }
        &:last-child {
          border-top: 1px solid #e1e1e1;
          padding-top: 10px;
          padding-bottom: 10px;
          margin-top: 15px;
        }
      }
    }
    .user-logged {
      background-color: #f8f8f8;
      padding: 15px 20px;
      margin-right: -16px;
      margin-left: -16px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      p {
        margin: 0;
        font-size: 14px;
        color: #606060;
        + p {
          color: #979797;
        }
      }
    }
  }
}

.modal-recover-password {
  .modal-content {
    top: 100px;
    border-radius: 16px;
  }
}

.modal-verify-email {
  max-width: 574px;
}

.modal-confirm-delete {
  top: 15vh;
  .modal-content {
    border-radius: 12px;
    padding: 0 25px;
    .modal-header {
      flex-direction: column;
      align-items: center;
      padding: 0;
      .modal-title {
        font-weight: 700;
        font-size: 32px;
        line-height: 38px;
        color: #000000;
        margin: 65px 0 11px 0;
      }
      img {
        position: absolute;
        top: 20px;
        left: 47.5%;
      }
    }
    .modal-body {
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: #575757;
      margin: 0 0 40px 0;
      padding: 0;
    }
    .modal-footer {
      border: none;
      justify-content: space-between;
      padding: 0;
      .btn {
        margin: 0 0 30px 0;
        text-transform: lowercase;
        &.cancel {
          font-size: 16px;
          border: none;
          margin-right: 20px;
          background: #ffffff;
          color: #979797;
        }
        &.confirm {
          width: 100%;
          max-width: 230px;
          height: 45px;
          border-radius: 8px;
          background: #292f31;
          color: #fff;
          font-weight: 500;
          font-size: 16px;
          &.green {
            background: #05cb95;
            border: 2px solid #05cb95;
            color: #ffffff;
            transition: all 0.2s;
          }
          &.green:hover {
            background: #0aa77c;
            border: 2px solid #0aa77c;
            color: #fff;
          }
          &.black {
            background: #292f31;
            border: 2px solid #292f31;
            color: #ffffff;
            transition: all 0.2s;
          }
          &.black:hover {
            background: #2b3133;
            border: 2px solid #2b3133;
            color: #fff;
          }
        }
        &:focus,
        &:active {
          border: none;
          box-shadow: none;
          outline: none;
        }
      }
    }
  }
  &.category {
    .modal-content {
      .modal-header {
        img {
          width: 50px;
          margin-bottom: 15px;
        }
      }
    }
  }
}

/* Menu Left Dashboard */

#wrapper {
  overflow-x: hidden;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: 0;
  position: fixed;
  z-index: 2;
  background-color: #ffffff;
  width: 260px;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.16);
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
  .header-sidebar {
    display: flex;
    align-items: center;
    padding: 5px 20px;
    .box-logo {
      width: 194px;
      height: 50px;
      overflow: hidden;
      display: flex;
      align-items: center;
      position: relative;
      img {
        position: absolute;
        left: 0;
      }
    }
  }
  .list-group {
    width: 100%;
    margin-top: 25px;
    height: 72vh;
    overflow: auto;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px #f5fcfa;
      border-radius: 0px;
      background-color: #ffffff;
    }
    &::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      background-color: #f5fcfa;
    }
    .collapse-menu {
      background: #f5fcfa;
      margin-top: -5px;
      padding-top: 5px;
      a {
        background: #f5fcfa;
        padding-left: 55px !important;
        padding-top: 0 !important;
        &:before {
          display: none;
        }
      }
    }
    .list-group-item {
      border: none;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      text-decoration: none;
      width: 100%;
      margin: 0 auto 5px;
      padding: 10px 0px 10px 20px;
      color: #2b2b2b;
      &:hover,
      &.active {
        background-color: #f5fcfa;
        color: #05cb95;
        &:before {
          content: "";
          display: block;
          width: 5px;
          height: 43px;
          color: #000;
          background: #62e1ba;
          position: absolute;
          left: 0px;
        }
      }
      i {
        margin-right: 10px;
        position: relative;
        top: 2px;
      }
      .down-arrow {
        position: absolute;
        top: 12px;
        right: 20px;
      }
    }
  }
  .btn-sidebar {
    text-align: left;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 10px;
    margin: 0 auto;
    left: 0;
    right: 0;
    border: none;
    border-radius: 7px;
    padding: 10px 8px 8px 20px;
    font-size: 15px;
    width: 90%;
    height: 65px;
    color: #484b4a;
    background: #ffffff;
    box-shadow: 0px 2px 15px 0px rgba(151, 151, 151, 0.7);
    text-decoration: none;
    outline: none;
    cursor: pointer;
    span {
      position: relative;
      font-size: 14px;
      top: 2px;
      right: 10px;
    }
  }
}

#wrapper.toggled {
  #menu-toggle {
    margin-left: 200px;
  }
  #sidebar-wrapper {
    margin-left: -12.5rem;
  }
  .list-group {
    .list-group-item {
      width: 50px;
      height: 50px;
      border-radius: 0px;
      padding: 10px 4px;
      margin-left: 208px;
      justify-content: center;
      i {
        margin-right: 0px;
      }
      span {
        display: none;
      }
      &:hover,
      &.active {
        &:before {
          left: -8px;
          height: 50px;
        }
      }
    }
  }
  .btn-sidebar {
    left: initial;
    right: 6px;
    width: 19%;
    height: 50px;
    padding: 10px 8px 8px 10px;
    span {
      top: 0;
      right: 0;
      margin: 0 auto;
    }
    span + span {
      display: none;
    }
  }
}

.list-group {
  .list-group-item {
    &:hover {
      ~ #wrapper {
        &.toggled {
          #sidebar-wrapper {
            margin-left: 0 !important;
          }
        }
      }
    }
  }
}

#menu-toggle {
  border: none;
  background-color: transparent;
  padding: 0;
  transition: all 0.2s;
  margin-right: 15px;
  &:hover {
    background-color: transparent;
  }
  &:focus {
    box-shadow: none;
  }
}

/* Home Dashboard */

.wrap-dashboard {
  .section-updates {
    margin-bottom: 40px;
  }
  h2 {
    font-size: 32px;
    color: #484b4a;
    margin-bottom: 60px;
  }
  h5 {
    font-size: 24px;
    color: #484b4a;
    margin-bottom: 20px;
  }
  .btn-new-order {
    background-color: #05cb95;
    color: #ffffff;
    border: none;
    border-radius: 30px;
    width: 148px;
    padding: 8px 0;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 18px;
      font-weight: bold;
      margin-right: 6px;
    }
  }
  .card-updates {
    background-color: #ffffff;
    box-shadow: 0px 2px 15px 0px #e2e2e2;
    border-radius: 6px;
    padding: 23px 15px;
    display: flex;
    align-items: center;
    height: 130px;
    &.card-week {
      height: 83px;
      margin-bottom: 10px;
      padding: 0px 15px 24px;
      .card-left {
        width: 110px;
        margin-right: 10px;
        p {
          &:first-child {
            margin-bottom: 5px;
          }
          &:last-child {
            color: #979797;
          }
        }
      }
      .card-right {
        p {
          position: relative;
          top: 30px;
        }
      }
    }
    &.card-chart {
      display: block;
      height: 455px;
      .form-group {
        width: 180px;
        margin-left: 15px;
        position: relative;
        select {
          box-shadow: none;
          outline: none;
          appearance: none;
          z-index: 1;
          position: relative;
          background: transparent;
          &:focus {
            border: 1px solid #05cb95;
            color: #05cb95;
          }
        }
      }
      .chart-area {
        height: 300px;
        margin-top: 75px;
      }
    }
  }
  .card-left {
    margin-right: 20px;
    width: 27px;
    height: 27px;
  }
  .card-right {
    p {
      &:nth-child(1) {
        font-size: 16px;
        color: #484b4a;
        margin: 0 0 10px;
        height: 22px;
      }
      &:nth-child(2) {
        font-size: 16px;
        color: #979797;
        margin: 0px;
        height: 22px;
      }
      &:nth-child(3) {
        font-size: 18px;
        color: #484b4a;
        margin: 0px;
        height: 22px;
      }
    }
  }
  .plain-select {
    &:after {
      content: url("assets/img/dashboard/select.svg");
      position: absolute;
      z-index: 2;
      right: 15px;
      top: 8px;
      right: 7px;
      border-radius: 5px;
      z-index: 0;
    }
  }
}

/* Create Category (private) */

.modal-create-category .create-category {
  background-color: #fff;
  padding: 0 40px;
  overflow-y: scroll;
}

.modal-create-category .create-category::-webkit-scrollbar {
  width: 0px;
}

.modal-header .close {
  padding: 0 !important;
  margin: 0 !important;
}

@media (max-width: 1024px) {
  .modal-create-category .container.create-category {
    padding: 0px 40px !important;
  }

  .modal-create-category {
    &.modal-dialog {
      margin: 0;
      position: absolute;
      right: 0;
      .modal-header {
        .close {
          opacity: 1;
          position: relative;
          top: 5px;
        }
      }
    }
    .modal-content {
      border: none;
      border-radius: 0;
      .create-category {
        .item-header {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 631px) {
  .modal-create-category .category--title h3 {
    padding: unset !important;
    width: 100% !important;
    max-width: 350px !important;
  }
}

@media (max-width: 510px) {
  .modal-create-category .container.create-category {
    width: 100vw !important;
    max-width: 490px !important;
    margin: 0 auto !important;
  }

  .modal-create-category .category--title h2 {
    max-width: unset !important;
  }

  .modal-create-category .container.create-category {
    padding: 0px 15px !important;
  }
}

.create-category {
  .modal-header {
    border-bottom: none;
    padding: 35px 20px 0px;
    h3 {
      padding-left: 0;
    }
    .close {
      outline: none;
      box-shadow: none;
      opacity: 0;
    }
  }
  .modal-body {
    padding-left: 5px;
    padding-right: 5px;
  }
  .create-category-form {
    height: 75vh;
    padding: 0 15px;
    label {
      font-size: 18px;
      font-weight: 500;
      color: #292f31;
      font-weight: 500;
      position: relative;
      width: 100%;
      margin-bottom: 5px;
      margin-top: 10px;
      span {
        font-size: 13px;
        color: #979797;
      }
      p {
        font-size: 12px;
        color: #979797;
        margin-top: 8px;
        margin-bottom: 0;
        line-height: 15px;
      }
    }
    select,
    input {
      height: 50px;
      &:focus {
        box-shadow: none;
        border: 2px solid #05cb95;
        color: #05cb95;
      }
    }
    select {
      appearance: none;
      background-color: transparent;
      position: relative;
      z-index: 1;
      color: #979797;
      font-size: 16px;
    }
    .f-name {
      padding-right: 20px;
    }
    .form-group {
      &.select-position {
        position: relative;
        .arrow-bottom {
          cursor: pointer;
        }
      }
    }
    .active-category {
      p {
        font-size: 12px;
        color: #979797;
        line-height: 15px;
        margin-bottom: 5px;
      }
    }
    input[type="checkbox"] {
      appearance: none;
      width: 70px !important;
      height: 11px;
      background: #575757;
      border-radius: 5px;
      position: relative;
      outline: 0;
      cursor: pointer;
      transition: all 0.25s;
      &:checked {
        background: #0aa77c;
        width: 70px !important;
        &:before {
          left: calc(100% - 25px);
          background: #05cb95;
        }
        &:after {
          left: 75px;
          transform: scale(0);
        }
        + .form-check-label {
          color: #05cb95;
        }
      }
      &:before,
      &:after {
        position: absolute;
        content: "";
        transition: all 0.25s;
      }
      &:before {
        width: 25px;
        height: 25px;
        background: #949494;
        border-radius: 50%;
        top: 50%;
        left: -1px;
        transform: translateY(-50%);
      }
    }
    input[type="radio"] {
      &:checked,
      &:not(:checked) {
        position: absolute;
        left: -9999px;
        + label {
          position: relative;
          padding-left: 28px;
          cursor: pointer;
          line-height: 20px;
          display: inline-block;
          color: #666;
          margin: 0px 17px 0px 0px;
          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 22px;
            height: 22px;
            border: 1px solid #979797;
            border-radius: 100%;
            background: #fff;
          }
          &:after {
            content: "";
            width: 10px;
            height: 10px;
            background: #62e1ba;
            position: absolute;
            top: 6px;
            left: 6px;
            border-radius: 100%;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
          }
        }
      }
      &:checked {
        + label {
          color: #05cb95;
          &:before {
            border: 2px solid #62e1ba;
          }
          &:after {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
          }
        }
      }
      &:not(:checked) {
        + label {
          &:after {
            opacity: 0;
            -webkit-transform: scale(0);
            transform: scale(0);
          }
        }
      }
    }
    .form-check {
      input {
        &:focus {
          border: none;
        }
      }
    }
    .form-check-label {
      font-size: 16px;
      font-weight: 100;
      margin: 0px;
      color: #999999;
    }
    .buttons {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      padding: 0 20px;
    }
    .btn-create-category {
      background: #05cb95;
      border-radius: 8px;
      color: #fff;
      width: 300px;
      height: 55px;
      margin: 100px 0 100px;
      outline: none;
      box-shadow: none;
      fill: #fff;
      transition: background-color 0.2s;
      margin-right: 70px;
    }
    .btn-create-category svg {
      margin-right: 5px;
    }
    .btn-create-category:hover {
      background: #16a880;
    }
    .btn-cancel {
      background-color: #ffffff;
      color: #a7a8a8;
      margin: 100px 0 100px;
      font-size: 16px;
      outline: none;
      box-shadow: none;
      transition: color 0.2s;
      padding: 0;
    }
    .btn-cancel:hover {
      color: #05cb95;
    }
  }
  span.help {
    border: 1px solid #05cb95;
    border-radius: 30px;
    width: 15px;
    height: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 10px !important;
    font-weight: bold;
    position: relative;
    top: -2px;
    right: 0px;
    color: #05cb95 !important;
    cursor: pointer;
    margin-left: 5px;
  }
  ::-webkit-input-placeholder {
    color: #979797;
    font-size: 16px;
  }
  ::-moz-placeholder {
    color: #979797;
    font-size: 16px;
  }
  :-ms-input-placeholder {
    color: #979797;
    font-size: 16px;
  }
  :-moz-placeholder {
    color: #979797;
    font-size: 16px;
  }
  .menu-icon {
    background: url("assets/img/dashboard/icons-menu/menu.svg");
    background-repeat: no-repeat;
    width: 24px;
    height: 19px;
    display: block;
    position: relative;
    top: 2px;
    &:hover {
      background: url("assets/img/dashboard/icons-menu/menu-hover.svg");
      background-repeat: no-repeat;
      width: 24px;
      height: 19px;
      display: block;
    }
  }
  .back-icon {
    background-image: url("assets/img/dashboard/back.svg");
    background-repeat: no-repeat;
    width: 30px;
    height: 14px;
    display: inline-block;
    position: relative;
    top: 3px;
  }
}

/* Landingpage */

.navbar-expand-lg {
  .collapse {
    .navbar-nav {
      margin-left: 60px;
    }
    img {
      display: none;
    }
    .navbar-nav li {
      margin-right: 50px;
      a {
        font-size: 18px;
        color: #484b4a;
        transition: all 0.2s;
        cursor: pointer;
        &:hover {
          color: #05cb95;
        }
      }
    }
    .buttons-right {
      display: flex;
      a {
        &:first-child {
          background: #05cb95;
          text-decoration: none;
          border-radius: 30px;
          padding: 10px 35px;
          font-size: 16px;
          color: #fff;
          transition: all 0.2s;
          &:hover {
            background: #16a880;
          }
        }
        &:last-child {
          background: #fff;
          text-decoration: none;
          border-radius: 30px;
          padding: 10px 40px;
          font-size: 16px;
          color: #05cb95;
          border: 1px solid #05cb95;
          margin-left: 20px;
          transition: all 0.2s;
          &:hover {
            color: #16a880;
            border: 1px solid #16a880;
          }
        }
      }
    }
  }
}

.section-top {
  margin-top: 17vh;
  h1 {
    font-family: "Arial-Rounded-MT-Bold";
    font-size: 95px;
    line-height: 95px;
    color: #484b4a;
    margin-top: 25px;
    span {
      color: #05cb95;
    }
  }
  h2 {
    font-family: "Arial-Rounded-MT";
    font-size: 60px;
    color: #373938;
    margin-bottom: 16vh;
  }
  .infos-left {
    p {
      font-size: 20px;
      color: #373938;
    }
    .buttons {
      position: relative;
      box-shadow: none;
      padding: 0;
    }
    button {
      background: #fff;
      padding: 16px 18px;
      border-radius: 4px;
      border: none;
      box-shadow: 0px 3px 15px 0px #e2e2e2;
      margin-left: 20px;
      color: #373938;
      outline: none;
      img,
      .infos-bottom button {
        margin-right: 10px;
      }
    }
  }
  .images-right {
    display: flex;
    align-items: baseline;
    img {
      &:first-child {
        margin-right: -95px;
        top: 80px;
        position: relative;
        z-index: 0;
      }
      &:last-child {
        position: relative;
        z-index: 1;
      }
    }
  }
}

.section-body-1 {
  background: #f8f8f8;
  margin-top: 25vh;
  padding-top: 130px;
  padding-bottom: 130px;
  height: auto;
  .body-1-left {
    position: relative;
    width: 350px;
    margin-top: 150px;
    img {
      &:nth-child(2) {
        position: absolute;
        top: 200px;
        right: 34px;
      }
      &:nth-child(3) {
        position: absolute;
        top: 345px;
        right: -80px;
      }
    }
  }
  .body-1-right {
    position: relative;
    width: 100%;
    h2 {
      font-size: 60px;
      margin-bottom: 35px;
      font-family: "Arial-Rounded-MT-Bold";
      color: #373938;
      span {
        color: #05cb95;
      }
    }
    .row-cards {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 240px;
      .card-body-1-right {
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.16);
        width: 203px;
        padding: 15px;
        height: 193px;
        transition: all 0.2s;
        &:hover {
          cursor: pointer;
          background: #62e1ba;
          height: 233px;
          transition: all 0.2s;
          p,
          .header-card p {
            color: #fff;
          }
          .header-card {
            margin-top: 30px;
          }
        }
        .header-card {
          display: flex;
          justify-content: space-between;
          margin-top: 8px;
          height: 45px;
          span {
            margin-right: 16px;
          }
          p {
            font-size: 19.5px;
            color: #373938;
            line-height: 23px;
            letter-spacing: 0.1px;
            font-weight: 500;
            margin-top: 0;
          }
        }
      }
    }
    .owl-carousel {
      display: none;
    }
    p {
      margin-top: 30px;
      font-size: 15.1px;
      line-height: 19px;
      &.info {
        font-size: 15px;
        color: #373938;
        font-family: "Aileron-Semi-Bold";
        margin-top: 5px;
      }
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 60px;
      a {
        &:first-child {
          border-radius: 30px;
          border: 2px solid #373938;
          text-decoration: none;
          font-weight: 500;
          padding: 15px;
          color: #373938;
          width: 35%;
          text-align: center;
          transition: all 0.2s;
          &:hover {
            color: #16a880;
            border: 2px solid #16a880;
          }
        }
        &:last-child {
          border-radius: 30px;
          border: 2px solid #05cb95;
          background: #05cb95;
          text-decoration: none;
          font-weight: 500;
          padding: 15px;
          color: #fff;
          width: 50%;
          text-align: center;
          transition: all 0.2s;
          &:hover {
            background: #16a880;
            border: 2px solid #16a880;
          }
        }
      }
    }
  }
}

.section-body-2 {
  padding-bottom: 130px;
  .titles {
    margin: 0 auto;
    text-align: center;
    h2 {
      font-size: 60px;
      margin-bottom: 15px;
      margin-top: 120px;
      font-family: "Arial-Rounded-MT-Bold";
      color: #373938;
      span {
        color: #05cb95;
      }
    }
    h3 {
      font-family: "Aileron-Semi-Bold";
      font-size: 25px;
      color: #979797;
      margin-top: 0;
      margin-bottom: 60px;
    }
  }
  .card-plan {
    border: 2px solid transparent;
    border-radius: 10px;
    padding: 15px 25px 20px;
    text-align: center;
    h4 {
      font-weight: 400;
      margin-top: 15px;
      color: #373938;

    }
    .price {
      text-align: center;
      margin-top: 27px;
      s {
        font-size: 16px;
        color: #c9c8c8;
        text-align: right;
        display: block;
        margin-right: 20px;
      }
      h3 {
        font-size: 40px;
        color: #373938;
        margin-bottom: 0;
        margin-top: -5px;
        font-family: "Aileron-Bold";
      }
      p {
        font-size: 16px;
        color: #373938;
        margin-top: -8px;
        text-align: right;
        font-family: "Aileron-Semi-Bold";
      }
    }
    a {
      border-radius: 30px;
      border: 2px solid #373938;
      text-decoration: none;
      color: #373938;
      display: block;
      padding: 10px;
      width: 100%;
      transition: all 0.2s;
      &:hover {
        color: #fff;
        border: 2px solid #05cb95;
        background: #05cb95;
      }
    }
    &.popular {
      background: #effbf7;
      border: 2px solid #05cb95;
      p {
        &:first-child {
          font-size: 14px;
          color: #373938;
          margin-bottom: 5px;
          font-family: "Aileron-Semi-Bold";
        }
      }
      h4 {
        color: #05cb95;
      }
      a {
        border-radius: 30px;
        border: 2px solid #05cb95;
        background: #05cb95;
        text-decoration: none;
        color: #fff;
        display: block;
        padding: 10px;
        width: 100%;
      }
    }
    &.executive,
    &.chef,
    &.unlimited {
      img {
        margin-top: 26px;
      }
    }
    &.unlimited {
      s {
        margin-right: 10px;
      }
    }
  }
  .nav {
    margin-top: 25px;
    li {
      display: flex;
      align-items: baseline;
      line-height: 2px;
      img {
        margin-right: 10px;
      }
      p {
        font-size: 16px;
        color: #aaaaaa;

      }
    }
    &.popular {
      li {
        p {
          color: #05cb95;
        }
      }
    }
  }
  .info-plans {
    font-size: 18px;
    color: #606060;
    background: #efefef;
    padding: 10px 25px;
    margin-top: 95px;
  }
}

.section-body-3 {
  .title {
    margin: 0 auto;
    text-align: center;
    h2 {
      font-family: "Arial-Rounded-MT";
      font-size: 60px;
      color: #fff;
    }
  }


  .infos-bottom {
    margin: 0 auto;
    text-align: center;
    .buttons {
      a {
        img {
          border-radius: 4px;
        }
      }
    }
    p {
      font-size: 20px;
      color: #373938;
      margin-bottom: 10px;
      font-family: "Aileron-Semi-Bold";
    }
    button {
      background: #fff;
      padding: 15px 18px;
      border-radius: 4px;
      border: none;
      box-shadow: 0px 3px 15px 0px #62e1ba;
      margin-left: 20px;
      color: #373938;
      outline: none;
      img {
        margin-right: 6px;
      }
    }
  }
}

footer {
  padding-top: 60px;
  padding-bottom: 10px;
  h5 {
    color: #373938;
    font-size: 18px;
    margin-top: 15px;
    font-family: "Aileron-Semi-Bold";
  }
  .nav {
    margin-top: 10px;
    .nav-item {
      .nav-link {
        padding-left: 0;
        padding-bottom: 0;
        font-size: 14px;
        color: #606060;
        transition: all 0.2s;
        &:hover {
          color: #05cb95;
        }
      }
    }
  }
  .nav-socials {
    margin-bottom: 25px;
    li {
      a {
        padding-right: 20px;
      }
    }
  }
  .copy {
    p {
      margin-top: 100px;
      margin-bottom: 30px;
      font-size: 14px;
      color: #606060;
      text-align: center;
      width: 100%;
    }
  }
  .navbar-brand {
    img {
      width: 100%;
    }
  }
}

.modal-menu-mobile {
  &.modal-dialog {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0;
    position: fixed;
    right: 0;
    left: 0;
    height: 100%;
  }
  .modal-content {
    height: 100%;
    border: none;
    border-radius: 0;
  }
  .modal-header {
    display: flex;
    align-items: center;
    img {
      width: 161px;
      display: block;
    }
  }
  .modal-body {
    .navbar-nav {
      margin-top: 50px;
      li {
        text-align: left;
        margin-top: 25px;
        margin-bottom: 0;
        font-size: 18px;
        color: #484b4a;

        .nav-link {
          position: relative;
          &:hover {
            color: #05cb95;
          }
        }
      }
    }
    .buttons {
      margin-top: 28vh !important;
      display: block;
      a {
        &:nth-child(1) {
          width: 100%;
          text-align: center;
          display: block;
          position: relative;
          margin: 0 auto 35px;
          background-color: #fff;
          color: #2bbf92;
          border: 1px solid #2bbf92;
          border-radius: 30px;
          height: 45px;
          padding-top: 10px;
          font-size: 16px;
          font-family: "Aileron-Semi-Bold";
        }
        &:last-child {
          width: 100%;
          text-align: center;
          display: block;
          position: relative;
          margin: 0 auto;
          background-color: #05cb95;
          color: #fff;
          border-radius: 30px;
          height: 45px;
          padding-top: 10px;
          font-size: 16px;
          font-family: "Aileron-Semi-Bold";
        }
      }
    }
  }
}

.modal-contact-us {
  display: flex;
  align-items: center;
  justify-content: center;
  .modal-content {
    .modal-header {
      margin: 43px 0px 40px 0px;
      width: 392px;
      h3 {
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        color: #292f31;
        width: 100%;
        button {
          &.close {
            position: absolute;
            right: 35px;
            box-shadow: none !important;
            outline: none !important;
            border: none !important;
          }
        }
      }
    }
    .modal-body {
      form {
        width: 100%;
        margin: 0 auto;
        label {
          font-size: 18px;
          font-family: "Aileron-Semi-Bold";
          color: #292f31;
          margin-bottom: 3px;
        }
        input {
          height: 47px;
          border: 1px solid #9e9e9e;
          &:focus {
            box-shadow: none;
            border: 1px solid #05cb95;
            &::-webkit-input-placeholder,
            &::-moz-placeholder,
            &:-ms-input-placeholder,
            &:-moz-placeholder {
              color: #05cb95;
              font-size: 14px;
            }
          }
        }
        textarea {
          height: 155px;
          resize: none;
          border: 1px solid #9e9e9e;
          &:focus {
            box-shadow: none;
            border: 1px solid #05cb95;
            &::-webkit-input-placeholder,
            &::-moz-placeholder,
            &:-ms-input-placeholder,
            &:-moz-placeholder {
              color: #05cb95;
              font-size: 14px;
            }
          }
        }
        span {
          font-size: 14px;
          color: #606060;
        }
        button {
          width: 100%;
          border-radius: 30px;
          background: #05cb95;
          color: #fff;
          padding: 10px;
          border: none;
          box-shadow: none;
          margin-top: 30px;
          margin-bottom: 35px;
          outline: none;
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .modal-contact-us {
    .modal-content {
      .modal-header {
        width: 326px;
      }
    }
  }
}

@media (max-width: 359px) {
  .modal-contact-us {
    .modal-content {
      .modal-header {
        width: 300px;
      }
    }
  }
}

@media (max-width: 335px) {
  .modal-contact-us {
    .modal-content {
      .modal-header {
        width: 280px;
      }
    }
  }
}

/* Initial Register */

.content-register {
  max-width: 463px;
  margin: 0 auto;
  h2 {
    text-align: center;
    font-size: 60px;
    font-family: "Arial-Rounded-MT-Bold";
    color: #05cb95;
    margin-top: 60px;
  }
  h3 {
    text-align: center;
    font-size: 38px;
    font-family: "Arial-Rounded-MT-Bold";
    color: #373938;
    margin-top: 50px;
    margin-bottom: 10px;
  }
  .nav-tabs {
    border: none;
    li {
      a {
        border: none;
        height: 0;
        padding: 0;
      }
    }
  }
  p {
    text-align: center;
    font-size: 14px;
    font-family: "Aileron-Semi-Bold";
    color: #606060;
    width: 100%;
    margin: 0 auto 60px;
  }
  label {
    font-size: 18px;
    font-family: "Aileron-Semi-Bold";
    color: #373938;
    span {
      font-size: 13px;
      color: #979797;

    }
  }
  .form-group {
    position: relative;
    .dropdown-menu {
      width: 100%;
      .dropdown-item {
        &:hover,
        &:focus,
        &.active {
          background-color: #05cb95;
        }
      }
    }
  }
  input {
    margin-top: 15px;
    height: 47px;
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &:-moz-placeholder {

      color: #8a8a8a;
      font-size: 14px;
    }
    &:focus {
      box-shadow: none;
      border: 1px solid #05cb95;
      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder,
      &:-moz-placeholder {
        color: #05cb95;
      }
    }
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 65px;
    margin-bottom: 50px;
    &.b-2 {
      justify-content: space-between;
    }
  }
}

/* Login */

.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  background: #fff;
}

.wrap-login100 {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: row-reverse;
  background: linear-gradient(269.83deg, #effbf7 40.81%, #ffffff 55.69%);
  .login100-more {
    width: 50%;
    position: relative;
    z-index: 1;
    .content-more {
      width: 535px;
      position: absolute;
      right: 0;
      .section-publicity {
        margin-top: 12%;
        h3 {
          color: #292f31;
          font-size: 55px;
          font-family: "Nunito-Bold";
        }
        p {
          color: #808285;
          font-size: 18px;
          line-height: 28px;
          margin-top: 20px;
          margin-bottom: 40px;
          width: 477px;
        }
        .box-images-cards {
          position: relative;
          img {
            &:nth-child(1) {
              margin: 30px 0 0 30px;
            }
            &:nth-child(2) {
              position: absolute;
              bottom: -39px;
              left: 10px;
            }
            &:nth-child(3) {
              position: absolute;
              bottom: -68px;
              left: 110px;
            }
          }
          .cards {
            position: absolute;
            top: 10px;
            right: 96px;
            .card {
              border-radius: 15px;
              border: none;
              box-shadow: 0px 5px 15px 0px rgba(151, 182, 196, 0.7);
              width: 264px;
              padding: 10px 20px;
              margin-bottom: 22px;
              p,
              h4 {
                margin: 0;
              }
              span {
                top: 0;
                margin: 0;
              }
              .check {
                width: 40px;
                height: 40px;
                background: #32db86;
                border-radius: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: -20px;
                top: -8px;
                img {
                  width: 13px;
                  margin: 0;
                }
              }
              &:nth-child(1) {
                p {
                  color: #32db86;
                  font-weight: 500;
                  font-size: 16px;
                }
                span {
                  font-size: 14px;
                  color: #808285;
                }
                h4 {
                  font-size: 25px;
                  color: #292f31;
                  font-weight: bold;
                  margin-top: 5px;
                }
              }
              &:nth-child(2) {
                p {
                  color: #292f31;
                  font-size: 16px;
                }
                h4 {
                  font-weight: 500;
                  color: #808285;
                  font-size: 25px;
                }
                .check {
                  background: #abb3bf;
                }
              }
              &:nth-child(3) {
                p {
                  font-weight: 500;
                  color: #292f31;
                  font-size: 16px;
                }
                h4 {
                  font-weight: bold;
                  color: #29b6f6;
                  font-size: 25px;
                }
                .check {
                  background: #29b6f6;
                }
              }
            }
          }
        }
        .link-store {
          display: block;
          border-radius: 10px;
          border: 1px solid #29b6f6;
          font-size: 15px;
          font-weight: 500;
          color: #29b6f6;
          background: #edf6f9;
          text-decoration: none;
          text-align: center;
          padding: 13px;
          margin-top: 100px;
          transition: all 0.2s;
          width: 460px;
          height: 49px;
        }
        span {
          color: #292f31;
          font-size: 13px;
          display: block;
          position: relative;
          line-height: 16px;
          top: 20px;
          width: 100%;
          margin: 0 auto;
        }
        #info {
          color: #292f31;
          font-size: 15px;
          position: relative;
          left: -35px;
          margin: 15px 0 50px 0;
          a {
            color: #29b6f6;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .login100-form {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo {
      width: 100%;
      text-align: center;
      margin-top: 45px;
    }
    .login100-form-title {
      font-family: "Quicksand";
      font-size: 32px;
      font-weight: 700;
      color: #484b4a;
      line-height: 22px;
      text-align: center;
      margin-top: 130px;
      width: 100%;
      display: block;
    }
    p {
      width: 100%;
      text-align: center;
      color: #979797;

      font-size: 13px;
      margin-bottom: 30px;
    }
    .wrap-input100 {
      width: 370px;
      margin: 0px 0px 32px 0px;
      position: relative;
      label {
        font-family: "Quicksand";
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
      }
      .input100 {
        display: block;
        width: 392px;
        height: 50px;
        background: transparent;
        font-size: 16px;
        color: #757575;
        line-height: 1.2;
        padding: 0 15px;
        border: 1px solid #d5d5d5;
        border-radius: 8px;
        &:focus {
          box-shadow: none;
          outline: 1px solid #05cb95;
          border: 1px solid #05cb95;
          background-color: #f8fafb;
          color: #05cb95;
          &::placeholder {
            color: #05cb95;
          }
        }
        &:focus-within {
          fill: #05cb95;
        }
        &::placeholder {
          font-size: 16px;
          color: #8a8a8a;

        }
        &:-ms-input-placeholder {
          font-size: 14px;
          color: #8a8a8a;

        }
        &::-ms-input-placeholder {
          font-size: 14px;
          color: #8a8a8a;

        }
      }
      .icon-password {
        top: 48px;
        right: -5px;
      }
      small {
        font-size: 14px;
        color: #484b4a;
      }
    }
    .container-login100-form-btn {
      width: 100%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .login100-form-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 391px;
        height: 55px;
        border-radius: 8px;
        background: #05cb95;
        border: none;
        margin-top: 23px;
        font-size: 16px;
        color: #fff;
        transition: all 0.2s;
        &:focus {
          outline: none;
        }
        &:hover {
          background: #0aa77c;
        }
      }
    }
  }
  .w-full {
    width: 55%;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    .recover {
      margin-top: 3px;
      text-decoration: underline;
      color: #8a8a8a;
    }
  }
  .wrap-register {
    width: 55%;
    margin: 0 auto;
    text-align: center;
    p {
      font-size: 14px;
      color: #000000;

      margin: 50px 0 0px;
    }
    a {
      color: #484b4a;
      text-decoration: none;
      font-size: 18px;
      font-family: "Aileron-Semi-Bold";
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background: #f9f9f9;
      }
    }
  }
}

/* Modal recover password */

.content-recover-pass,
.email-sent {
  .email-send p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #949494;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      color: #292f31;
      margin: 0;
      font-family: "Quicksand";
      font-style: normal;
    }
    img {
      cursor: pointer;
      position: relative;
    }
  }
  p,
  .email-sent p {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #949494;
    text-align: center;
    margin: 0px;
  }
  .body {
    margin-top: 40px;
    label {
      font-size: 20px;
      color: #373938;
      font-family: "Aileron-Semi-Bold";
      position: relative;
      margin: 0px;
      span {
        &:first-child {
          font-size: 13px;
          color: #979797;

        }
        &:last-child {
          opacity: 0;
        }
      }
    }
    input {
      height: 50px;
      border-radius: 8px;
      font-size: 16px;
      border-color: #d5d5d5;
      width: 100%;
      &::placeholder {
        font-size: 16px;
        color: #757575;
      }
      &:focus {
        box-shadow: none;
        outline: 1px solid #05cb95;
        border: 1px solid #05cb95;
        color: #05cb95;
        background-color: #f8fafb;
        &::placeholder {
          color: #05cb95;
        }
      }
    }
  }
  .footer {
    margin-top: 35px;
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #05cb95;
      font-family: "Aileron-Semi-Bold";
      font-weight: 700;
      color: #ffffff;
      width: 100%;
      height: 55px;
      border-radius: 8px;
      font-size: 14px;
      transition: all 0.2s;
      &:hover {
        color: #ffffff;
        background: #0aa77c;
      }
      &:disabled {
        background: #f5f5f5;
        color: #292f31;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}

.email-sent {
  p {
    span {
      font-family: "Aileron-Semi-Bold";
      color: #484b4a;
    }
  }
  .body {
    margin-top: 30px;
  }
}

/* Alter Password (Public) */

.content-alter-password {
  width: 421px;
  margin: 50px auto 0;
  .logo {
    text-align: center;
  }
  .wrapper {
    margin-top: 100px;
    text-align: center;
    h4 {
      font-size: 35px;
      color: #373938;
      font-family: "Arial-Rounded-MT-Bold";
    }
    p {
      font-size: 16px;
      color: #8a8a8a;

    }
  }
  .validate-form {
    margin-top: 40px;
    .wrap-input100 {
      width: 100%;
      position: relative;
      margin-bottom: 30px;
      .input100 {
        display: block;
        width: 100%;
        height: 47px;
        background: transparent;
        font-size: 18px;
        color: #666666;
        line-height: 1.2;
        padding: 0 15px;
        border: 1px solid #9e9e9e;
        border-radius: 5px;
        &:focus {
          box-shadow: none;
          outline: none;
          border: 1px solid #05cb95;
          color: #05cb95;
          &::-webkit-input-placeholder {
            color: #05cb95;
          }
          &::-moz-placeholder {
            color: #05cb95;
          }
          &:-ms-input-placeholder {
            color: #05cb95;
          }
          &:-moz-placeholder {
            color: #05cb95;
          }
        }
        &::-webkit-input-placeholder {
          color: #8a8a8a;
          font-size: 16px;
        }
        &::-moz-placeholder {
          color: #8a8a8a;
          font-size: 16px;
        }
        &:-ms-input-placeholder {
          color: #8a8a8a;
          font-size: 16px;
        }
        &:-moz-placeholder {
          color: #8a8a8a;
          font-size: 16px;
        }
        &.invalid {
          box-shadow: none;
          border: 1px solid #d61d30;
          color: #d61d30;
          &::-webkit-input-placeholder {
            color: #d61d30;
          }
          &::-moz-placeholder {
            color: #d61d30;
          }
          &:-ms-input-placeholder {
            color: #d61d30;
          }
          &:-moz-placeholder {
            color: #d61d30;
          }
        }
      }
    }
    .login100-form-btn {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      width: 100%;
      height: 45px;
      border-radius: 30px;
      background: #05cb95;
      border: none;
      margin-top: 55px;
      font-size: 16px;
      color: #fff;
      transition: all 0.2s;
      font-family: "Aileron-Semi-Bold";
      &:focus {
        outline: none;
      }
      &:hover {
        background: #16a880;
      }
    }
  }
  .invalid-text-feedback {
    text-align: left;
  }
}

/* Modal Success Password (Public) */

.modal-dialog {
  &.modal-success-password {
    top: 170px;
    .modal-content {
      background: #05cb95;
      color: #ffffff;
      padding: 8px;
      .modal-header {
        h4 {
          font-family: "Arial-Rounded-MT-Bold";
          font-size: 24px;
        }
        img {
          cursor: pointer;
        }
      }
      .modal-body {
        p {
          font-family: "Aileron-Semi-Bold";
          font-size: 16px;
        }
      }
    }
  }
}

/* Initial Register (Public) */

#prev {
  background: #fff;
  border-radius: 30px;
  border: 1px solid #c9c8c8;
  padding: 8px 35px;
  font-size: 16px;
  color: #9e9e9e;
  transition: all 0.2s;
  text-decoration: none;
  &:hover {
    background: #f9f9f9;
  }
}

#next {
  background: #05cb95;
  border-radius: 30px;
  border: 1px solid #05cb95;
  padding: 8px 55px;
  font-size: 16px;
  color: #fff;
  transition: all 0.2s;
  text-decoration: none;
  text-align: center;
  font-family: "Aileron-Semi-Bold";
  &:hover {
    background: #16a880;
    border: 1px solid #16a880;
  }
}

.content-register {
  .buttons {
    button {
      background: #05cb95;
      border-radius: 30px;
      border: 1px solid #05cb95;
      padding: 10px 55px 7px;
      font-size: 16px;
      color: #fff;
      transition: all 0.2s;
      text-decoration: none;
      outline: none;
      &:hover {
        background: #16a880;
        border: 1px solid #16a880;
      }
    }
  }
}

.group-input {
  display: flex;
  justify-content: space-between;
}

.form-card {
  position: relative;
}

.showItem {
  display: block;
}

.hideItem {
  display: none;
}

.document-area {
  margin-top: 30px;
  p {
    &:first-child {
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      color: #606060;
    }
  }
  + p {
    font-size: 13.8px;
    color: #484b4a;
    font-weight: 100;
    letter-spacing: 0;
  }
}

.box__upload--add-logo .img-preview {
  left: 0 !important;
  right: 0 !important;
  margin: 0 auto;
  top: 0;
  bottom: 0;
}

.box__upload__create--logo .img-preview {
  width: 255px !important;
  left: 0 !important;
  right: 0 !important;
  margin: 0 auto;
  top: 0;
  bottom: 0;
  border-radius: unset !important;
  padding: 0 5px !important;
}

.boxs-upload {
  display: flex;
  justify-content: space-between;
  .box {
    width: 48.5%;
    height: 187px;
    border-radius: 5px;
    border: 2px dashed #9e9e9e;
    cursor: pointer;
    position: relative;
    &:hover {
      background: #f4fbf8;
      border: 2px dashed #05cb95;
    }
    .wrap-input100 {
      text-align: center;
      .img-preview {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        object-fit: contain;
      }
      .empty-imagem {
        margin-top: 25px;
        p {
          margin: 15px 0 0;
          font-weight: 600;
          font-size: 16px;
          color: #484b4a;
          line-height: 15px;
        }
        small {
          color: #484b4a;
          margin: 0;
        }
        span {
          display: block;
          color: #484b4a;
        }
      }
    }
    &:hover {
      .wrap-input100 {
        .empty-imagem {
          p,
          small,
          span {
            color: #05cb95;
          }
          span {
            text-decoration: underline;
          }
        }
      }
    }
    &.box-document-end {
      width: 100%;
      height: 285px;
      margin-top: 10px;
      &.invalid {
        border: 2px dashed #d61d30;
        .wrap-input100 {
          .empty-imagem {
            margin-top: 68px;
            p,
            small,
            span {
              color: #d61d30;
            }
          }
        }
      }
    }
  }
}

/* Auth - Establishment */

.smile-logo {
  margin-bottom: 17vh;
}

.container-green {
  background: #05cb95;
  font-family: "Arial-Rounded-MT-Bold";
  color: #fff;
  width: 100%;
  padding-top: 50px;
  position: fixed;
  bottom: 0;
  top: 0;
  h1 {
    font-size: 55px;
    width: 440px;
    margin: auto;
  }
  h4 {
    font-size: 22px;
    line-height: 32px;
    width: 460px;
    margin: 50px auto 0;
  }
  .col-button {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 48px;
    right: 115px;
  }
  #next {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #ffffff;
    outline: none;
    font-size: 16px;
    width: 150px;
    height: 50px;
    &:hover {
      background: transparent;
    }
  }
}

.container-green-left {
  background: #05cb95;
  font-family: "Arial-Rounded-MT-Bold";
  color: #fff;
  width: 33%;
  height: 100%;
  position: fixed;
  .smile-logo {
    margin-top: 65px;
  }
  h2 {
    font-size: 35px;
  }
  h4 {
    font-size: 18px;
    line-height: 27px;
    width: 335px;
    margin: 50px auto 0;
  }
  p {
    font-size: 17px;
    font-weight: bold;
    line-height: 27px;
    width: 360px;
    margin: 60px auto 0;
  }
}

.container-right {
  padding: 60px 110px;
  .progress {
    height: 8px !important;
    border-radius: 2px !important;
    .progress-bar-success {
      &.bg-success {
        background-color: #16a880 !important;
      }
    }
  }
  h2 {
    font-family: "Arial-Rounded-MT-Bold";
    font-size: 35px;
    color: #292f31;
    margin-top: 50px;
  }
  form,
  .wrap-form {
    .h-1 {
      height: 100px;
    }
    label {
      font-weight: 500;
      font-size: 18px;
      color: #292f31;
    }
    input {
      height: 45px;
      &::-webkit-input-placeholder {
        color: #aaaaaa;
        font-size: 14px;
      }
      &::-moz-placeholder {
        color: #aaaaaa;
        font-size: 14px;
      }
      &:-ms-input-placeholder {
        color: #aaaaaa;
        font-size: 14px;
      }
      &:-moz-placeholder {
        color: #aaaaaa;
        font-size: 14px;
      }
      &:focus {
        border: 1px solid #05cb95;
        box-shadow: none;
      }
      [type="checkbox"] {
        appearance: none;
        width: 80px;
        height: 10px;
        background: #a7a8a8;
        border-radius: 5px;
        position: relative;
        outline: 0;
        cursor: pointer;
        transition: all 0.25s;
        &:checked {
          background: #0aa77c;
          &:before {
            left: calc(100% - 25px);
            background: #05cb95;
          }
          &:after {
            left: 75px;
            transform: scale(0);
          }
        }
        &:before,
        &:after {
          position: absolute;
          content: "";
          transition: all 0.25s;
        }
        &:before {
          width: 25px;
          height: 25px;
          background: #c5c5c5;
          border-radius: 50%;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }
    }
  }
  input[type="radio"] {
    &:checked,
    &:not(:checked) {
      position: absolute;
      left: -9999px;
      + label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        font-size: 18px;
        display: inline-block;
        color: #c5c5c5;
        margin-right: 17px;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 22px;
          height: 22px;
          border: 1px solid #979797;
          border-radius: 100%;
          background: #fff;
        }
        &:after {
          content: "";
          width: 10px;
          height: 10px;
          background: #62e1ba;
          position: absolute;
          top: 6px;
          left: 6px;
          border-radius: 100%;
          -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease;
        }
      }
    }
    &:checked {
      + label {
        color: #05cb95;
        font-weight: 500;
        &:before {
          border: 2px solid #62e1ba;
        }
        &:after {
          opacity: 1;
        }
      }
    }
    &:not(:checked) {
      + label {
        &:after {
          opacity: 0;
          -webkit-transform: scale(0);
          transform: scale(0);
        }
      }
    }
  }
  .wrap-form {
    .card-select-plans {
      border-radius: 10px;
      box-shadow: 2px 4px 35px 0 rgba(0, 0, 0, 0.1);
      border: none;
      cursor: pointer;
      &:hover,
      &:focus-within {
        background: #05cb95;
        h3,
        h4,
        p,
        span {
          color: #ffffff;
        }
        input[type="radio"] {
          &:checked,
          &:not(:checked) {
            + label {
              &:after {
                content: "";
                width: 7px;
                height: 12px;
                display: block;
                border: solid #62e1ba;
                background: #ffffff;
                position: absolute;
                top: 4px;
                left: 8px;
                border-width: 0 3px 3px 0;
                transform: rotate(45deg);
                -webkit-transition: all 0.2s ease;
                transition: all 0.2s ease;
              }
              &:before {
                border: 2px solid #ffffff;
              }
            }
          }
        }
      }
      input[type="radio"] {
        &:checked,
        &:not(:checked) {
          + label {
            top: -5px;
          }
        }
      }
      .card-body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 25px;
        .wrap-radio-texts {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
      h4 {
        font-weight: bold;
        font-size: 28px;
        color: #292f31;
        margin-top: 5px !important;
      }
      h3 {
        font-weight: bold;
        font-size: 30px;
        color: #292f31;
        span {
          font-weight: bold;
          font-size: 14px;
          color: #808285;
          position: absolute;
          right: 21px;
          top: 73px;
        }
      }
      .card-title {
        margin: 0;
      }
      .card-text {

        font-size: 14px;
        color: #808285;
        margin: 0;
      }
      .old-price {
        margin-right: 33px;
      }
    }
    div {
      &:nth-child(5) {
        .old-price {
          margin-right: 48px;
        }
      }
    }
    .wrap-inputs-payment {
      padding: 40px 17px 20px;
      label {
        font-weight: 500;
        font-size: 18px;
        color: #292f31;
        span {
          font-size: 14px;
          color: #979797;
        }
      }
    }
  }
  .form-text {
    color: #05cb95;
    &.text-muted {
      text-decoration: underline;
      font-size: 14px;
      color: #aaaaaa !important;
      margin: 42px 0 0 10px;
    }
  }
  .buttons {
    margin-top: 16vh !important;
  }
  #prev {
    border: none;
    color: #808285;
    outline: none;
    padding: 8px 35px 8px 10px;
    &:hover {
      background: #ffffff;
    }
  }
  #next {
    height: 50px;
    outline: none;
  }
  .wrap-image-preview {
    width: 100px;
    height: 132px;
    border-radius: 5px;
    border: 1px solid #d8d9dd;
    text-align: center;
    &.identification {
      width: 120px;
      height: 90px;
      margin-top: 20px;
    }
    .img-preview {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    a {
      color: #05cb95;

      font-size: 16px;
      text-decoration: none;
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 120px;
      img {
        margin-right: 5px;
      }
    }
  }
  .col-progressbar {
    width: 60%;
    margin: auto;
  }
}

.content-success {
  text-align: center;
  h3 {
    font-size: 35px;
    font-family: "Arial-Rounded-MT-Bold";
    color: #292f31;
    margin-top: 70px;
    margin-bottom: 50px;
  }
  .success-detail {
    p {
      &:nth-child(1) {
        font-size: 20px;
        color: #373938;
        margin: 0;
      }
      &:nth-child(2) {
        margin: 4px;
        font-size: 16px;
        color: #979797;
      }
      &:nth-child(3) {
        margin: 0;
        font-size: 16px;
        color: #606060;
        line-height: 15px;
        text-transform: lowercase;
      }
    }
  }
  .card-payment {
    background: #05cb95;
    border-radius: 10px;
    width: 180px;
    height: 231px;
    margin: 40px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      color: #fff;
      font-size: 20px;
      margin-top: 15px;
      line-height: 25px;
    }
  }
  a {
    color: #2b2b2b;
    text-decoration: underline;
    font-size: 18px;
    transition: all 0.2s;
    &:hover {
      color: #05cb95;
    }
  }
}

/* Mobile Popup Info (Modal) */

.mobile-popup-info {
  &.modal-dialog {
    top: 45px;
    padding: 10px;
    .modal-content {
      background: #05cb95;
      color: #ffffff;
      border-radius: 10px;
      .modal-header {
        justify-content: flex-end;
        margin-bottom: 20px;
        img {
          position: relative;
          top: 10px;
          right: 10px;
          cursor: pointer;
        }
      }
      .modal-body {
        text-align: center;
        padding: 10px 30px;
        h2 {
          font-family: "Arial-Rounded-MT-Bold";
        }
        p {
          font-size: 16px;
          line-height: 27px;
          margin-bottom: 25px;
        }
      }
    }
  }
}

/* Upload Files (Modal) */

.modal-upload-files {
  &.modal-dialog {
    max-width: 65% !important;
    width: 40%;
    margin-top: 0;
    position: fixed;
    right: 0;
    height: 100%;
  }
  .modal-content {
    height: 100%;
    border: none;
    border-radius: 0;
  }
  .modal-header {
    justify-content: center;
    align-items: center;
    h4 {
      font-weight: 500;
      font-size: 24px;
      color: #484b4a;
    }
    img {
      position: absolute;
      right: 20px;
      cursor: pointer;
    }
  }
  .modal-body {
    padding: 0 20px;
  }
  .box {
    &.box-document-end {
      height: 70vh;
      background: #f8fffd;
      cursor: inherit;
      .edit-image {
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 70px;
        border-radius: 5px;
        cursor: pointer;
        .icon {
          width: 45px;
          height: 45px;
          background: #05cb95;
          border-radius: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 20px;
        }
        span {
          color: #ffffff;
          font-size: 16px;
        }
      }
    }
  }
  .empty-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 22vh;
    img {
      width: 65px;
      margin: auto;
    }
    button {
      width: 136px;
      height: 35px;
      background: #dbeee9;
      border-radius: 5px;
      font-size: 13px;
      color: #16a880;
      margin: 25px auto 12px;
      outline: none;
      box-shadow: none;
    }
    small {
      font-size: 13px;
      color: #808285;
      span {
        font-weight: 500;
        color: #484b4a;
      }
    }
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    margin-top: 40px;
    button {
      &:first-child {
        width: 50%;
        height: 50px;
        border-radius: 30px;
        background-color: #05cb95;
        color: #ffffff;
        font-size: 16px;
        box-shadow: none;
      }
      &:last-child {
        width: 150px;
        height: 50px;
        border-radius: 30px;
        background-color: #ffffff;
        color: #979797;
        font-size: 16px;
        border: 2px solid #c5c5c5;
        box-shadow: none;
      }
    }
  }
}

/* Mass Actions (Modal) */

.modal-actions {
  &.modal-dialog {
    position: fixed;
    right: 0;
    margin: 0;
    height: 100%;
  }
  .modal-content {
    height: 100%;
    border: none;
    border-radius: 0;
  }
}

/* Quick Edit item (Modal) */

.modal-quick-edit {
  width: 100vw;
  max-width: 554px;
  .modal-content {
    width: 100vw;
    max-width: 554px;
  }
}

@media (max-width: 631px) {
  .modal-quick-edit {
    margin: 0 !important;
    width: 100%;
    max-width: unset;
    .modal-content {
      width: 100%;
      max-width: unset;
    }
  }
}

/* Initial Area */

.logo-gif {
  width: 25%;
  margin-top: 60px;
}

.title-wait {
  text-align: center;
  font-size: 40px;
  font-weight: 500;
  color: #05cb95;
}

.sub-title-wait {
  font-size: 25px;
  color: #707070;
}

/* Icons */

.arrow-icon {
  content: url("assets/img/dashboard/icons-register-product/select.svg");
  position: absolute;
  z-index: 1;
  right: -17px;
  fill: #292f31;
  border-radius: 5px;
  top: 6px;
}

.magnifier-icon {
  background-image: url("assets/img/dashboard/icons-register-product/magnifier.svg");
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  display: inline-block;
  position: relative;
  margin-right: 10px;
  top: 5px;
}

.add-item-icon {
  background-image: url("assets/img/dashboard/icons-register-product/add-item.svg");
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  display: inline-block;
  position: relative;
  margin-right: 5px;
  top: 7px;
}

.icon-burger {
  background-image: url("assets/img/landingpage/icons/burger.svg");
  background-repeat: no-repeat;
  width: 50px;
  height: 27px;
}

.icon-manager {
  background-image: url("assets/img/landingpage/icons/manager.svg");
  background-repeat: no-repeat;
  width: 45px;
  height: 38px;
}

.icon-cart {
  background-image: url("assets/img/landingpage/icons/cart.svg");
  background-repeat: no-repeat;
  width: 56px;
  height: 38px;
}

.icon-near {
  background-image: url("assets/img/landingpage/icons/near.svg");
  background-repeat: no-repeat;
  width: 56px;
  height: 38px;
}

.icon-register-menu {
  background-image: url("assets/img/landingpage/icons/register-menu.svg");
  background-repeat: no-repeat;
  width: 56px;
  height: 38px;
}

.icon-fees {
  background-image: url("assets/img/landingpage/icons/fees.svg");
  background-repeat: no-repeat;
  width: 56px;
  height: 38px;
}

.icon-contract {
  background-image: url("assets/img/landingpage/icons/contract.svg");
  background-repeat: no-repeat;
  width: 30px;
  height: 38px;
}

.arrow-help {
  background: url("assets/img/landingpage/icons/arrow.svg");
  position: absolute;
  top: 20px;
  right: 20px;
  width: 19px;
  height: 11px;
}

.quick-edit-icon {
  background-image: url("assets/img/dashboard/icons-register-product/quick-edit.svg");
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  display: inline-block;
  position: relative;
  top: 5px;
  &:hover {
    background-image: url("assets/img/dashboard/icons-register-product/quick-edit-hover.svg");
    background-repeat: no-repeat;
  }
}

.delete-icon {
  background-image: url("assets/img/dashboard/delete.svg");
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  display: inline-block;
  position: relative;
  margin-left: 20px;
  top: 5px;
  &:hover {
    background-image: url("assets/img/dashboard/delete-hover.svg");
    background-repeat: no-repeat;
  }
}

.edit-icon {
  background-image: url("assets/img/dashboard/edit.svg");
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  display: inline-block;
  position: relative;
  margin-left: 7px;
  top: 5px;
  &:hover {
    background-image: url("assets/img/dashboard/edit-hover.svg");
    background-repeat: no-repeat;
  }
}

.icon-password {
  background-image: url("assets/img/login/see.svg");
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  display: block;
  position: absolute;
  right: 15px;
  top: 12px;
  cursor: pointer;
  &.error {
    background-image: url("assets/img/login/see-error.svg");
  }
  &.show-pass {
    background-image: url("assets/img/login/see-click.svg");
  }
}

.input100 {
  &:focus {
    ~ .icon-password {
      background-image: url("assets/img/login/see-green.svg");
      &.error {
        background-image: url("assets/img/login/see-error.svg");
      }
    }
  }
}

button.disabled {
  background-color: #f5f5f5 !important;
  color: #292f31 !important;
}

.opened {
  .arrow-help {
    background: url("assets/img/landingpage/icons/arrow-selected.svg");
    position: absolute;
    top: 20px;
    right: 20px;
    width: 19px;
    height: 11px;
  }
}

.menu-icon {
  background: url("assets/img/dashboard/icons-menu/menu.svg");
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  display: block;
  position: relative;
  top: 2px;
  &:hover {
    background: url("assets/img/dashboard/icons-menu/menu-hover.svg");
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    display: block;
    position: relative;
    top: 2px;
  }
}

.close-icon {
  background: url("assets/img/landingpage/icons/close.svg");
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  display: block;
  position: relative;
  top: 2px;
}

.arrow-bottom {
  background: url("assets/img/landingpage/icons/arrow-two.svg");
  position: absolute;
  top: 13px;
  right: 15px;
  width: 20px;
  height: 20px;
}

.down-arrow {
  background: url("assets/img/dashboard/select.svg");
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  position: relative;
  top: 2px;
  margin-left: 3px;
}

.icon-logout {
  background: url("assets/img/dashboard/logout.svg");
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  top: 4px;
}

.icon-internal-home {
  background: url("assets/img/dashboard/icons-menu/home.svg");
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  display: inline-block;
}

.icon-internal-ordered {
  background: url("assets/img/dashboard/icons-menu/ordered.svg");
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  display: inline-block;
}

.icon-internal-report {
  background: url("assets/img/dashboard/icons-menu/report.svg");
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  display: inline-block;
}

.icon-internal-drinks {
  background: url("assets/img/dashboard/icons-menu/drinks.svg");
  background-repeat: no-repeat;
  width: 25px;
  height: 30px;
  display: inline-block;
  top: 6px;
}

.icon-internal-categories {
  background: url("assets/img/dashboard/icons-menu/categories.svg");
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  display: inline-block;
}

.icon-internal-complements {
  background: url("assets/img/dashboard/icons-menu/complements.svg");
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  display: inline-block;
}

.icon-internal-notifications {
  background: url("assets/img/dashboard/icons-menu/notifications.svg");
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  display: inline-block;
  top: 9px;
}

.icon-internal-personalization {
  background: url("assets/img/dashboard/icons-menu/personalization.svg");
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  display: inline-block;
  top: 13px;
}

.icon-internal-carte {
  background: url("assets/img/dashboard/icons-menu/carte.svg");
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  display: inline-block;
}

.icon-internal-operation {
  background: url("assets/img/dashboard/icons-menu/operation.svg");
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  display: inline-block;
}

.icon-internal-deliveries {
  background: url("assets/img/dashboard/icons-menu/deliveries.svg");
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  display: inline-block;
}

.icon-internal-financial {
  background: url("assets/img/dashboard/icons-menu/financial.svg");
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  display: inline-block;
}

.icon-internal-plan {
  background: url("assets/img/dashboard/icons-menu/plan.svg");
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  display: inline-block;
}

.icon-internal-profile {
  background: url("assets/img/dashboard/icons-menu/profile.svg");
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  display: inline-block;
}

.icon-internal-clients {
  background: url("assets/img/dashboard/icons-menu/client.svg");
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  display: inline-block;
}

.icon-internal-password {
  background: url("assets/img/dashboard/password.svg");
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  display: inline-block;
}

.icon-internal-logout {
  background: url("assets/img/dashboard/logout.svg");
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  display: inline-block;
}

.icon-support {
  background: url("assets/img/dashboard/icons-menu/support.svg");
  background-repeat: no-repeat;
  width: 45px;
  height: 36px;
  display: block;
  position: relative;
  top: 30px;
}

.profile-menu {
  .dropdown-menu {
    .menuitem {
      a {
        &:hover {
          .icon-logout {
            background: url("assets/img/dashboard/logout-hover.svg");
            background-repeat: no-repeat;
            width: 20px;
            height: 20px;
            display: inline-block;
            position: relative;
            top: 4px;
          }
        }
      }
    }
  }
}

/* Personalization */

.wrap-personalization {
  p {
    font-size: 0px;
    line-height: 0px;
    margin: 0px;
    color: #808285;
    strong {
      color: #292f31;
    }
    a {
      text-decoration: underline;
      color: rgb(62, 193, 245);
    }
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 25px;
    margin-top: 150px;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #05cb95;
      border: 1px solid #05cb95;
      color: #ffffff;
      width: 219px;
      height: 50px;
      border-radius: 30px;
      font-size: 16px;
      outline: none;
      box-shadow: none;
      &:disabled {
        background: #dddddd;
        border: 1px solid #dddddd;
        color: #979797;
      }
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      border: 1px solid #979797;
      color: #979797;
      width: 135px;
      height: 50px;
      border-radius: 30px;
      font-size: 16px;
      outline: none;
      box-shadow: none;
      text-decoration: none;
    }
  }
}

.tab-customized {
  &.reg-data {
    .tab-content {
      padding-top: 0px;
      .tab-pane {
        height: 300px;
      }
      .header {
        .mb-1 {
          display: none;
        }
        div {
          border-bottom: 1px solid #d5d5d5;
          padding: 0 0 2px 0;
          margin-left: 88%;
          width: 100%;
        }
      }
    }
  }
  .tab-content {
    padding-top: 0px;
    .header {
      .mb-1 {
        display: none;
      }
      div {
        border-bottom: 1px solid #d5d5d5;
        padding: 0 0 1px 0;
        margin-left: 88%;
        width: 100%;
      }
    }
  }
  .nav {
    position: absolute;
    width: 88%;
    top: -15px;
    z-index: 1;
    border-bottom: 1px solid #d5d5d5;
  }
  .nav-item {
    height: 41px;
    .nav-link {
      font-size: 18px;
      color: #808285;
      padding-left: 0;
      padding-right: 0;
      margin-right: 40px;
      border: none;
      border-bottom: 3px solid transparent;
      &.active {
        border-color: transparent;
        font-weight: 500;
        color: #05cb95;
        height: 43px;
        border-bottom: 3px solid #05cb95;
      }
    }
  }
}

@media (max-width: 475px) {
  .tab-customized {
    .nav {
      position: absolute;
      width: 80%;
      top: -15px;
      z-index: 1;
      border-bottom: 1px solid #d5d5d5;
    }
  }
}

/* Tab customized  (responsive)*/

@media (max-width: 767px) {
  .tab-customized {
    .registration-data-form {
      .form-group {
        &.f-email {
          padding-right: 5px;
        }
      }
    }
  }
}

@media (max-width: 475px) {
  .tab-customized .tab-content .header div {
    border-bottom: 1px solid #d5d5d5;
    padding: 0 0 2px 0;
    margin-left: 80%;
    width: 100%;
  }
}

.access-business-form {
  .dropdown.open {
    max-width: 92%;
  }
  .form-row {
    .form-group {
      &:nth-child(3) {
        .dropdown.open {
          max-width: 97%;
        }
      }
    }
  }
}

.location-form {
  .dropdown.open {
    max-width: 96%;
  }
}

/* Personalization  (responsive)*/

@media (max-width: 767px) {
  .no-styles {
    .col-lg-12 {
      padding-right: 0;
      padding-left: 0;
      .photo-area {
        margin-left: 0;
      }
    }
  }
  .tab-customized {
    .nav {
      flex-wrap: initial;
    }
  }
}

/* Hover and Active Icons */

.collapse-custom-menu {
  li {
    &:hover,
    &.active {
      .down-arrow {
        background: url("assets/img/dashboard/icons-menu/select-hover.svg");
        background-repeat: no-repeat;
      }
      .icon-internal-home {
        background: url("assets/img/dashboard/icons-menu/home-hover.svg");
        background-repeat: no-repeat;
      }
      .icon-internal-ordered {
        background: url("assets/img/dashboard/icons-menu/ordered-hover.svg");
        background-repeat: no-repeat;
      }
      .icon-internal-report {
        background: url("assets/img/dashboard/icons-menu/report-hover.svg");
        background-repeat: no-repeat;
      }
      .icon-internal-drinks {
        background: url("assets/img/dashboard/icons-menu/drinks-hover.svg");
        background-repeat: no-repeat;
      }
      .icon-internal-categories {
        background: url("assets/img/dashboard/icons-menu/categories-hover.svg");
        background-repeat: no-repeat;
      }
      .icon-internal-complements {
        background: url("assets/img/dashboard/icons-menu/complements-hover.svg");
        background-repeat: no-repeat;
      }
      .icon-internal-notifications {
        background: url("assets/img/dashboard/icons-menu/notifications-hover.svg");
        background-repeat: no-repeat;
      }
      .icon-internal-personalization {
        background: url("assets/img/dashboard/icons-menu/personalization-hover.svg");
        background-repeat: no-repeat;
      }
      .icon-internal-carte {
        background: url("assets/img/dashboard/icons-menu/carte-hover.svg");
        background-repeat: no-repeat;
      }
      .icon-internal-operation {
        background: url("assets/img/dashboard/icons-menu/operation-hover.svg");
        background-repeat: no-repeat;
      }
      .icon-internal-deliveries {
        background: url("assets/img/dashboard/icons-menu/deliveries-hover.svg");
        background-repeat: no-repeat;
      }
      .icon-internal-financial {
        background: url("assets/img/dashboard/icons-menu/financial-hover.svg");
        background-repeat: no-repeat;
      }
      .icon-internal-plan {
        background: url("assets/img/dashboard/icons-menu/plan-hover.svg");
        background-repeat: no-repeat;
      }
      .icon-internal-profile {
        background: url("assets/img/dashboard/icons-menu/profile-hover.svg");
        background-repeat: no-repeat;
      }
      .icon-internal-clients {
        background: url("assets/img/dashboard/icons-menu/client-hover.svg");
        background-repeat: no-repeat;
      }
      .icon-internal-password {
        background: url("assets/img/dashboard/password-hover.svg");
        background-repeat: no-repeat;
      }
    }
  }
  .list-group {
    .list-group-item {
      &.active {
        .down-arrow {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.card-body-1-right:hover,
.owl-item.active .card-body-1-right {
  .icon-burger {
    background-image: url("assets/img/landingpage/icons/burger-hover.svg");
  }
  .icon-manager {
    background-image: url("assets/img/landingpage/icons/manager-hover.svg");
  }
  .icon-cart {
    background-image: url("assets/img/landingpage/icons/cart-hover.svg");
  }
  .icon-near {
    background-image: url("assets/img/landingpage/icons/near-hover.svg");
  }
  .icon-register-menu {
    background-image: url("assets/img/landingpage/icons/register-menu-hover.svg");
  }
  .icon-fees {
    background-image: url("assets/img/landingpage/icons/fees-hover.svg");
  }
  .icon-contract {
    background-image: url("assets/img/landingpage/icons/contract-hover.svg");
  }
}

.icon-image-placeholder {
  background-image: url("assets/img/auth_establishment/icon-placeholder.svg");
  background-repeat: no-repeat;
  width: 49px;
  height: 43px;
  display: block;
  margin: 0 auto;
}

.icon-photo-placeholder {
  background-image: url("assets/img/dashboard/placeholder-img.svg");
  background-repeat: no-repeat;
  width: 59px;
  height: 59px;
  display: block;
  margin: 0 auto;
}

.boxs-upload {
  .box {
    &:hover {
      .icon-image-placeholder {
        background-image: url("assets/img/auth_establishment/icon-placeholder-hover.svg");
        background-repeat: no-repeat;
        width: 49px;
        height: 43px;
        display: block;
        margin: 0 auto;
      }
    }
    &.box-document-end {
      &.invalid {
        .icon-image-placeholder {
          background-image: url("assets/img/auth_establishment/icon-placeholder-invalid.svg");
          background-repeat: no-repeat;
          width: 49px;
          height: 43px;
          display: block;
          margin: 0 auto;
        }
      }
    }
  }
}

/* Landingpage (Responsive) */

@media (max-width: 991px) {
  .navbar {
    &.navbar-landing {
      padding: 0 !important;
      .navbar-brand {
        width: 160px;
        position: relative;
        top: 5px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .navbar-toggler {
        border: none;
        padding: 0;
        .navbar-toggler-icon {
          color: #373938;
        }
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      .navbar-collapse {
        position: fixed;
        background: #fff;
        top: 0;
        width: 100%;
        height: 500px;
        z-index: 2;
        margin-left: -15px;
        box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.38);
        img {
          margin: 10px auto 0;
          width: 161px;
          display: block;
        }
        li {
          text-align: center;
          margin-top: 25px;
          margin-bottom: 0;
        }
        .buttons-right {
          margin-top: 40px !important;
          display: block;
          a {
            &:first-child {
              width: 80%;
              text-align: center;
              display: block;
              position: relative;
              top: 85px;
              margin: 0 auto;
            }
            &:last-child {
              width: 80%;
              text-align: center;
              display: block;
              position: relative;
              top: -35px;
              margin: 0 auto;
            }
          }
        }
      }
      .close-menu {
        width: 50px !important;
        margin-top: 100px !important;
      }
    }
  }
  .section-top {
    margin-top: 30px;
    overflow: hidden;
    height: 490px;
    h1 {
      font-size: 50px;
      width: 80%;
      line-height: 1;
      text-align: center;
      margin: 30px auto 0;
    }
    h2 {
      font-size: 40px;
      text-align: center;
      margin-bottom: 80px;
    }
    .infos-left {
      p {
        width: 70%;
        line-height: 1.1;
      }
      .buttons {
        a {
          img {
            width: 57%;
          }
        }
        button {
          margin-left: 0;
          margin-top: 18px;
          width: 57%;
          padding: 16px 10px;
        }
      }
    }
    .images-right {
      position: absolute;
      right: -160px;
      top: -290px;
      img {
        &:first-child {
          width: 95%;
        }
        &:last-child,
        .section-body-1 .buttons {
          display: none;
        }
      }
    }
  }
  .section-body-1 {
    margin-top: 15vh;
    padding-top: 60px;
    padding-bottom: 20px;
    .col-12 {
      padding-right: 0;
      padding-left: 0;
    }
    .container {
      padding-left: 0;
      padding-right: 0;
    }
    .body-1-left {
      display: none !important;
    }
    .body-1-right {
      .row-cards {
        display: none;
      }
      h2 {
        font-size: 34px;
        text-align: center;
        width: 89%;
        margin: 0 auto 30px;
      }
      p.info {
        font-size: 13px;
        text-align: center;
        line-height: 15px;
        width: 90%;
        margin: 30px auto 55px;
      }
      .buttons {
        display: none;
      }
      .owl-carousel {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 240px;
        .owl-stage {
          height: 260px;
          display: flex;
          align-items: center;
        }
        .card-body-1-right {
          border-radius: 10px;
          background: #fff;
          box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.16);
          width: 95%;
          padding: 15px;
          height: 193px;
          transition: all 0.2s;
          .header-card {
            display: flex;
            justify-content: space-between;
            margin-top: 8px;
            height: 45px;
            span {
              margin-right: 16px;
            }
            p {
              font-size: 19.5px;
              color: #373938;
              line-height: 23px;
              letter-spacing: 0.1px;
              font-weight: 500;
              margin-top: 0;
            }
          }
        }
        .owl-item {
          &.active {
            .card-body-1-right {
              background: #62e1ba;
              height: 233px;
              transition: all 0.2s;
              p,
              .header-card p {
                color: #fff;
              }
              .header-card {
                margin-top: 30px;
              }
            }
          }
        }
      }
    }
  }
  .section-body-2 {
    padding: 55px 0 65px 0 !important;
    .titles {
      h2 {
        font-size: 34px;
        margin-top: 10px;
      }
      h3 {
        font-family: "Aileron-Semi-Bold";
        font-size: 18px;
        margin-bottom: 35px;
      }
    }
    .col-lg-3 {
      display: none;
    }
    .owl-carousel {
      .card-plan {
        padding: 15px 17px 20px;
        a {
          padding: 6px;
        }
        img {
          width: 40%;
          height: 70px;
          margin: 0 auto;
        }
        &.executive,
        &.chef,
        &.unlimited {
          margin-top: 26px;
        }
        h4 {
          font-size: 20px;
        }
        .price {
          s {
            font-size: 15px;
            margin-right: 30px;
          }
          h3 {
            font-size: 30px;
          }
          p {
            margin-right: 30px;
          }
        }
      }
      .owl-item {
        .nav {
          li {
            img {
              width: 15px;
            }
            p {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
  .section-body-3 {
    .title {
      h2 {
        font-size: 31px;
        font-weight: 600;
      }
    }
    .infos-bottom {
      .buttons {
        display: flex;
        justify-content: space-between;
        &.buttons-bottom {
          justify-content: center;
          position: relative;
          background: transparent;
          box-shadow: none;
          padding: 0;
        }
        a {
          img {
            width: 158px;
          }
        }
      }
      button {
        margin-left: 10px;
        width: 158px;
        padding: 6px;
        text-align: left;
        font-size: 14px;
        img {
          margin-right: 5px;
          margin-left: 4px;
        }
      }
    }
  }
  footer {
    padding-top: 40px;
    padding-bottom: 10px;
    h5 {
      color: #373938;
      font-size: 18px;
      margin-top: 15px;
      font-family: "Aileron-Semi-Bold";
    }
    .nav {
      margin-top: 10px;
      .nav-item {
        .nav-link {
          padding-left: 0;
          padding-bottom: 0;
          font-size: 14px;
          color: #606060;
          transition: all 0.2s;
          &:hover {
            color: #05cb95;
          }
        }
      }
    }
    .nav-socials {
      margin-bottom: 25px;
      li {
        a {
          padding-right: 20px;
        }
      }
    }
    .copy {
      p {
        margin-top: 50px;
        margin-bottom: 120px;
        font-size: 14px;
        color: #606060;
        text-align: center;
        width: 100%;
      }
    }
    .navbar-brand {
      img {
        width: 100%;
      }
    }
  }
  .btn-floating {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.3);
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 68px;
    background: #fff;
    padding-right: 15px;
    padding-left: 15px;
    z-index: 10;
  }
  .btn-floating a {
    background: #05cb95;
    color: #fff;
    border-radius: 30px;
    padding: 10px 40px;
    outline: none;
  }
  .btn-floating img {
    margin-right: 15px;
  }
  .modal-contact-us {
    .modal-content {
      .modal-header {
        h3 {
          margin-top: 0;
        }
        .close {
          margin-top: -2px;
        }
      }
    }
  }
}

/* Initial Register (Responsive) */

.back-to-top {
  width: 55px;
  height: 55px;
  display: block;
  text-align: center;
  background: #373938;
  position: fixed;
  bottom: 24px;
  right: 93px;
  cursor: pointer;
  border-radius: 30px;
  transition: all 0.2s;
  img {
    position: relative;
    top: 13px;
    pointer-events: none;
  }
}

/* Login (Responsive) */

@media (min-width: 993px) and (max-width: 1440px) {
  .wrap-input100,
  .login100-form-btn,
  .w-full,
  .login100-form .register {
    width: 75%;
  }
}

@media (max-width: 992px) {
  .login100-form {
    width: 60%;
    padding-left: 30px;
    padding-right: 30px;
  }
  .login100-more {
    width: 40%;
  }
  .content-more {
    width: 90%;
  }
}

@media (max-height: 880px) {
  .login100-form {
    height: 120vh;
  }
}

@media (max-height: 700px) {
  .login100-form {
    height: 130vh;
  }
}

@media (max-width: 768px) {
  .wrap-login100 {
    .login100-form {
      background-color: #ffffff;
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 30px;
      .login100-form-title {
        margin-top: 45px;
      }
      .wrap-input100 {
        width: 100%;
      }
      .container-login100-form-btn {
        .login100-form-btn {
          width: 100%;
          margin-top: 15px;
        }
      }
    }
    .w-full {
      width: 100%;
      justify-content: center;
    }
    .wrap-register {
      width: 100%;
      p {
        margin-top: 35px;
      }
      a {
        height: 52px;
      }
    }
  }
  .login100-more {
    display: none;
  }
}

/* Modal Recover password (Responsive) */

/* Alter Password Responsive (Public) */

@media (max-width: 991px) {
  .content-alter-password {
    width: 100%;
    margin-top: 15px;
    .wrapper {
      margin-top: 40px;
      h4 {
        font-size: 24px;
      }
    }
    .container-login100-form-btn {
      position: fixed;
      bottom: 0;
      background: #fff;
      box-shadow: 0px 3px 15px 0px rgb(0 0 0 / 30%);
      width: 100%;
      left: 0;
      height: 68px;
      padding: 12px 15px;
      .login100-form-btn {
        margin-top: 0 !important;
      }
    }
  }
}

/* Success (Responsive) */

@media (max-width: 991px) {
  .content-success h3 {
    font-size: 35px;
    margin-top: 40px;
  }
  .content-success a {
    font-size: 15px;
  }
  .container-right {
    .col-progressbar {
      width: 100%;
    }
  }
}

/* Auth - Establishment (Responsive)*/

@media (min-width: 1600px) {
  .wrap-form {
    width: 80% !important;
  }
  .container-title {
    .title-plans {
      padding-left: 110px !important;
    }
  }
}

@media (max-width: 1366px) {
  .container-green-left {
    background: #05cb95;
    font-family: "Arial-Rounded-MT-Bold";
    color: #fff;
    width: 33%;
    height: 100%;
    position: fixed;
    .smile-logo {
      margin-top: 30px;
      margin-bottom: 15vh;
    }
    h2 {
      font-size: 35px;
    }
    h4 {
      font-size: 18px;
      line-height: 27px;
      width: 335px;
      margin: 30px auto 0;
    }
    p {
      font-size: 17px;
      font-weight: bold;
      line-height: 27px;
      width: 360px;
      margin: 60px auto 0;
    }
  }
}

@media (max-width: 767px) {
  .container-right {
    padding: 40px 0px;
    h2 {
      margin-top: 10px;
    }
    input,
    label {
      margin-left: 0 !important;
    }
    #next {
      width: 65%;
      padding: 8px 10px;
    }
    .h-1 {
      &.pr-1 {
        padding-right: 15px !important;
      }
      &.pl-1 {
        padding-left: 15px !important;
      }
    }
    .wrap-add {
      margin-top: 30px !important;
      margin-bottom: 80px !important;
    }
    .form-check-inline {
      margin-right: 0;
    }
    .wrap-form {
      margin-bottom: 70px;
      padding-left: 0;
      padding-right: 0;
      .card-select-plans {
        .card-body {
          flex-direction: column;
          align-items: flex-start;
          .wrap-radio-texts {
            .texts {
              padding-left: 12px;
              margin-bottom: 13px;
            }
          }
          .wrap-prices {
            padding-left: 40px;
          }
        }
        h3 {
          span {
            right: inherit;
            top: 128px;
          }
        }
      }
      .wrap-inputs-payment {
        padding-left: 0;
        padding-right: 0;
        label {
          span {
            img {
              position: relative;
              top: -3px;
            }
          }
        }
        .group-input {
          .col-md-6 {
            &:nth-child(1) {
              padding-right: 7px;
            }
            &:nth-child(2) {
              padding-left: 7px;
            }
          }
        }
        .row {
          &.mb-3,
          &.mb-2 {
            margin-bottom: 0 !important;
          }
        }
      }
    }
    input[type="radio"] {
      &:checked,
      &:not(:checked) {
        + label {
          margin-right: 0;
          top: 22px !important;
        }
      }
    }
  }
  .container-title {
    padding: 0 !important;
    .col {
      padding: 7px !important;
    }
  }
  #col-wrap-plans {
    .container {
      padding: 0 !important;
    }
  }
  .container-green {
    h1 {
      font-size: 40px;
      width: 95%;
    }
    h4 {
      font-size: 18px;
      width: 90%;
    }
    .col-button {
      justify-content: center;
      right: 0;
    }
  }
}

/* Modal Upload-Files (Responsive)*/

@media (max-width: 767px) {
  .modal-upload-files {
    &.modal-dialog {
      max-width: 100% !important;
      width: 100%;
      margin: 0;
    }
    .modal-header {
      justify-content: space-between;
    }
  }
}

/* Create Category (private) - (Responsive) */

.modal-create-sizes{
  animation: slideIn 0.5s ease-in-out !important;
}

@media (max-width: 767px) {
  .create-category {
    .modal-header {
      padding: 13px 0 0 0;
    }
    .modal-body {
      padding-left: 0;
      padding-right: 0;
      height: 85vh;
    }
    .create-category-form {
      padding: 0;
      .f-name {
        padding-right: 5px;
      }
    }
    .create-category-form select,
    .create-category-form input {
      width: 100%;
      height: 45px;
    }
    .create-category-form .form-group.select-position label {
      line-height: 18px;
    }
    .create-category-form .plain-select:after,
    .create-category-form .plain-select.checked:after {
      left: inherit;
      right: 10px;
      bottom: 2px;
    }
    .create-category-form .buttons button:first-child {
      width: 320px;
      margin: 0px 0px 20px 0px;
      transition: all 0.2s;
    }
    .create-category-form .buttons button:last-child {
      width: 113px;
      margin: 0;
    }
    span.help {
      top: -3px;
    }
  }
  .modal-create-category {
    &.modal-dialog {
      margin: 0;
      position: absolute;
      right: 0;
      .modal-header {
        .close {
          opacity: 1;
          position: relative;
          top: 5px;
        }
      }
    }
    .modal-content {
      border: none;
      border-radius: 0;
      .create-category {
        .item-header {
          display: none;
        }
      }
    }
  }
  .modal-create-sizes {
    animation: slideIn 0.5s ease-in-out !important;
    &.modal-dialog {
      margin: 0;
      float: right;
      .modal-header {
        .close {
          opacity: 1;
          position: relative;
          top: 5px;
        }
      }
    }
    .modal-content {
      border: none;
      border-radius: 0;
      min-height: 100vh;
      .create-category {
        .item-header {
          display: none;
        }
      }
      .add-rates-areas-form {
        .d-flex-m {
          flex-direction: column;
        }
        .btn-add-rates {
          width: 100%;
          margin-top: 35px;
        }
      }
      .buttons {
        width: 100%;
        position: fixed;
        .btn-create-rates {
          margin: 0 !important;
          width: 180px !important;
        }
        .btn-cancel {
          margin: 0 !important;
          width: 120px !important;
        }
      }
    }
  }
}

@media (min-width: 767px) {
  .modal-create-category {
    &.modal-dialog {
      max-width: 50% !important;
      margin-top: 0;
      position: fixed;
      right: 0;
      height: 100%;
      .modal-header {
        .close {
          opacity: 1;
          position: relative;
          top: 5px;
          padding-right: 5px;
        }
      }
      .modal-content {
        height: 100%;
        border: none;
        border-radius: 0;
        .create-category {
          .item-header {
            display: none;
          }
          .create-category-form {
            height: 85vh;
            .col-md-9 {
              flex: 0 0 100%;
              max-width: 100%;
            }
            .col-md-3 {
              flex: 0 0 50%;
              max-width: 50%;
            }
            .f-name {
              padding-right: 0;
            }
          }
        }
      }
    }
  }
  .input-group .invalid-text-feedback {
    font-size: 14px;
    color: #d61d30 !important;
    margin-top: 0px;
    position: absolute;
    bottom: -22px !important;
    left: 0px !important;
    width: 200px !important;
  }
  .modal-create-sizes {
    &.modal-dialog {
      max-width: 50% !important;
      margin-top: 0;
      position: fixed;
      right: 0;
      height: 100%;
      .modal-header {
        margin: 33px 0 39px 0;
        .close {
          opacity: 1;
          position: relative;
          padding-right: 5px;
        }
      }
      .modal-content {
        height: 105vh;
        border: none;
        border-radius: 0;
        .create-category {
          .item-header {
            display: none;
          }
          .create-category-form {
            height: 85vh;
            .col-md-9 {
              flex: 0 0 100%;
              max-width: 100%;
            }
            .col-md-3 {
              flex: 0 0 50%;
              max-width: 50%;
            }
            .f-name {
              padding-right: 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 766px) {
  .input-group .invalid-text-feedback {
    font-size: 14px;
    color: #d61d30 !important;
    margin-top: 0px;
    position: absolute;
    bottom: -22px !important;
    left: 0px !important;
    width: 200px !important;
  }
}

@media(max-width: 430px){
  .modal-create-sizes {
    &.modal-dialog {
      width: 100% !important;
      max-width: unset !important;
      .speed-actions__full{
        padding: 0 15px !important;
      }
    }
  }
}

/* Initial Area - (Responsive) */

@media (max-width: 767px) {
  .logo-gif {
    margin-top: 0;
    width: 100%;
  }
  .title-wait {
    font-size: 30px;
  }
  .sub-title-wait {
    font-size: 20px;
  }
}

/* all (Responsive) */

@media (min-width: 1367px) and (max-width: 1440px) {
  .content-dashboard-small {
    .card-right {
      p {
        &:nth-child(1) {
          font-size: 16px !important;
        }
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1366px) {
  .content-dashboard-small {
    .card-right p:nth-child(1),
    .card-updates.card-week .card-left p:first-child,
    .content-dashboard-small .card-updates.card-week .card-left p:last-child {
      font-size: 12.5px !important;
    }
    .card-right p:nth-child(2),
    .card-right p:nth-child(3) {
      font-size: 14px !important;
    }
    .card-left {
      margin-right: 2px !important;
      img {
        width: 20px !important;
        height: 20px !important;
      }
    }
    .card-updates {
      height: 100px !important;
      &.card-chart {
        height: initial !important;
      }
      &.card-week {
        height: 80px !important;
        .card-left {
          width: 85px !important;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  #wrapper {
    &.toggled {
      #sidebar-wrapper {
        margin-left: -17.5rem;
      }
    }
  }
  /* Mass Actions (Modal) */
}

@media (max-width: 576px) {
  .boxs-upload {
    flex-direction: column;
    .box {
      width: 100%;
      margin-bottom: 30px;
      &.box-document-end {
        margin-bottom: 0;
      }
    }
  }
  .content-register {
    .buttons {
      margin: 40px 0;
    }
  }
  #street {
    width: 70%;
    margin-right: 15px;
  }
}

@media (max-width: 631px) {
  .wrap-container.category__container {
    .item-header {
      display: flex;
      align-items: center;
      padding: 20px 0px;
      a {
        margin-bottom: 0;
      }
    }
    h3 {
      padding-left: 0;
    }
    #menu-toggle {
      border: none;
      background-color: transparent;
      padding: 0;
      transition: all 0.2s;
      margin-right: 25px;
      &:hover {
        background-color: transparent;
      }
      &:focus {
        box-shadow: none;
      }
    }
    .title-buttons {
      flex-direction: column;
      padding: 50px 0px 0px 0px;
      width: 350px;
    }
    @media (max-width: 631px) {
      .title-buttons {
        width: 300px;
      }
    }
    .links {
      padding-right: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      a {
        &:last-child {
          padding: 2px 18px;
          height: 45px;
          font-size: 15px;
        }
      }
    }
    .item-body {
      .container {
        padding-left: 0;
        padding-right: 0;
      }
      .search {
        input::-webkit-input-placeholder {
          font-size: 14px;
        }
        input::-moz-placeholder {
          font-size: 14px;
        }
        input:-ms-input-placeholder {
          font-size: 14px;
        }
        input:-moz-placeholder {
          font-size: 14px;
        }
      }
      .filters {
        .btn-filter {
          width: 100%;
          margin-top: 20px;
        }
      }
    }
    .input-group-text {
      span {
        display: none;
      }
    }
    .lupa-icon {
      left: 5px;
      width: 20px;
    }
    .wrap-items {
      .nav-header-items {
        li {
          &:nth-child(2) {
            span + span {
              display: block;
            }
          }
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5) {
            opacity: 0;
          }
        }
      }
      .nav-body-items {
        margin-top: 0;
        margin-bottom: 15px;
        padding-left: 10px;
        justify-content: space-between;
        position: relative;
        li {
          &:nth-child(1) {
            position: absolute;
            left: 21px;
            top: 32px;
          }
          &:nth-child(2) {
            width: 100%;
            margin-top: 45px;
          }
          &:nth-child(3) {
            position: absolute;
            top: 82px;
            right: 22px;
            width: unset;
          }
          &:nth-child(4) {
            position: absolute;
            right: 147px;
            top: 32px;
            width: 45px;
            label {
              display: none;
            }
          }
          &:nth-child(5) {
            position: absolute;
            right: 19px;
            top: 32px;
            width: 88px;
          }
        }
        &.items-category {
          li {
            &:nth-child(3) {
              margin-top: 0px;
            }
          }
        }
        .nav-item {
          .item-price-qtd {
            p {
              width: 50% !important;
            }
            span {
              margin: 0 6px 0 9px;
              width: 55px;
            }
          }
          img {
            min-width: 80px;
          }
          p {
            width: 100%;
            line-height: 22px;
          }
        }
        &.disabled {
          li {
            &:nth-child(4) {
              right: 53px;
            }
            &:nth-child(5) {
              right: 140px;
            }
          }
        }
      }
      li:nth-child(5) {
        width: 40%;
      }
    }
    .item-price-qtd {
      display: flex;
      justify-content: flex-end;
      width: 97%;
      &.ctg-position {
        justify-content: space-between;
      }
    }
    .magnifier-icon {
      margin-right: 0;
      width: 20px;
    }
  }
}

.width-columns-orders {
  grid-template-columns: 5.12% 11.94% 16.21% 12.8% 19.76% 25.18% 7.99%;
}

@media (max-width: 390px) {
  .modal-body {
    padding: 0px 20px 0px 20px;
  }
}

@media (max-width: 380px) {
  .modal-body {
    padding: 0px 10px 0px 20px;
  }
}

@media (max-width: 375px) {
  .modal-body {
    padding: 0px 20px 0px 40px;
  }
}

.form-controlf {
  display: block;
  width: 863px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #aaaaaa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d9dd;
  border-radius: 8px 0px 0px 8px;
}

.form-controlf:focus {
  outline: #05db95;
  color: #05db95;
}

.form-controlf:checked .input-group-text {
  border: solid 1px green;
}

.input-groupf {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 853px;
}

@media (max-width: 1070px) {
  .create-category .modal-header {
    padding: 35px 20px 0px 5px;
  }

  .create-category-form {
    padding: 0px 0px 0px 0px;
  }

  .modal-create-category.modal-dialog
    .modal-content
    .create-category
    .create-category-form {
    padding: 0px;
  }
}

@media (max-width: 1024px) {
  .create-category .create-category-form .buttons {
    display: flex;
    flex-direction: column;
    padding: 100px 0px 100px 0px;
  }

  .create-category .create-category-form .btn-cancel {
    margin: 0px 0 100px;
  }

  .create-category .create-category-form .btn-create-category {
    margin-right: 0px;
    margin: 0px 0px 20px 0px;
  }
}

@media (max-width: 395px) {
  .modal-create-category.modal-dialog .modal-header .close {
    padding: 0px;
  }
}

@media (max-width: 320px) {
  .create-category .modal-header {
    width: 340px;
  }
}

.modal-content {
  width: unset;
  border: unset;
  background-color: unset;
}

.modal-create-sizes .modal-content::-webkit-scrollbar {
  width: 0px;
}

.modal-dialog.single-rate {
  max-width: 545px !important;
}

.container.details .select__comingsoon .app-select__list {
  display: none !important;
}

.container.details .select__comingsoon .app-select__list {
  position: relative;
  padding: 10px 14px;
  border-radius: 8px;
  background-color: #fff;
  list-style: none;
  padding-right: 30px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.list-orders .list-orders__container {
  .card__new {
    display: none;
    cursor: default;
  }
  .card__preparing {
    display: none;
  }
  .card__ready {
    display: none;
  }
}

.orders__new .list-orders {
  .list-orders__circle {
    background: #05cb95;
  }
  .length__container {
    background: #05cb95;
  }
  .list-orders__container .card__new {
    display: flex;
  }
}

.orders__preparing .list-orders {
  .list-orders__circle {
    background: #ecef8b;
  }
  .length__container {
    background: #ecef8b;
  }
  .list-orders__container .card__preparing {
    display: flex;
  }
}

.orders__ready .list-orders {
  .list-orders__circle {
    background: #19e2ef;
  }
  .length__container {
    background: #19e2ef;
  }
  .list-orders__container .card__ready {
    display: flex;
  }
}

.recover__full {
  .wrap-input100 {
    width: 100% !important;
  }
  .input__code span input {
    width: 45px !important;
    height: 52px !important;
    border: 1px solid #d5d5d5 !important;
    border-radius: 8px !important;
    &:focus {
      border: 1px solid #05cb95 !important;
      outline: 1px solid #05cb95 !important;
    }
  }
  .input__code span:first-child {
    padding: 0 10px 0 0 !important;
  }
  .input__code span:last-child {
    padding: 0 !important;
  }
  .input__code--error {
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    color: #eb3941 !important;
  }
  .input__code {
    margin: 6px 0 !important;
  }
}

.modal-notification {
  max-width: 429px;
  background: #fff;
  border-radius: 24px;
}

.modal-profile-acess{
  max-width: 554px;
  background: #fff;
  border-radius: 24px;
  height: 86vh;
  max-height: 562px;
}

.modal-business{
  max-width: 554px;
  max-height: 554px;
  background: #fff;
  border-radius: 24px;
  height: 86vh;
  typeahead-container{
    border-radius: 12px !important;
    top: 1px !important;
  }
}

.modal-address{
  max-width: 554px;
  background: #fff;
  border-radius: 24px;
  height: 86vh;
  max-height: 767px;
}

.modal-sac{
  max-width: 554px;
  background: #fff;
  border-radius: 24px;
  height: 86vh;
}

.modal-new-doc{
  max-width: 405px;
  background: #fff;
  border-radius: 24px;
  height: 86vh;
}

.modal-view-doc{
  max-width: 600px;
  background: #fff;
  border-radius: 24px;
  height: 86vh;
}

.modal-warn-timer{
  max-width: 378px;
  background: #fff;
  border-radius: 24px;
}

.modal-sound{
  max-width: 415px;
  background: #fff;
  border-radius: 24px;
}

.modal-warn-print{
  max-width: 415px;
  background: #fff;
  border-radius: 24px;
}

.modal-print{
  max-width: 554px;
  background: #fff;
  border-radius: 24px;
  height: 86vh;
  max-height: 555px;
}

.modal-owner{
  max-width: 554px;
  background: #fff;
  border-radius: 24px;
  height: 86vh;
  max-height: 554px;
}

.modal-complements{
  max-width: 1050px;
  height: 100vh;
  background: #fff;
  margin: 0 0 0 auto;
  animation: slideIn 0.5s ease-in-out !important;
}

.modal-filter-complements{
  max-width: 554px;
  height: 100vh;
  background: #fff;
  margin: 0 0 0 auto;
  animation: slideIn 0.5s ease-in-out !important;
}

.modal-filter-orders {
  max-width: 554px;
  height: 100vh;
  background: #fff;
  margin: 0 0 0 auto;
  animation: slideIn 0.5s ease-in-out !important;
}

.delete-complement-modal{
  max-width: 413px;
  background: #fff;
  border-radius: 24px;
}

@media(max-width: 631px){
  .modal-profile-acess{
    margin: 0;
    max-width: unset;
    max-height: unset;
    border-radius: 0;
    height: 100vh;
  }

  .modal-business{
    margin: 0;
    max-width: unset;
    border-radius: 0;
    height: 100vh;
    max-height: unset;
  }

  .modal-address{
    margin: 0;
    max-width: unset;
    border-radius: 0;
    height: 100vh;
    max-height: unset;
  }

  .modal-owner{
    margin: 0;
    max-width: unset;
    border-radius: 0;
    height: 100vh;
    max-height: unset;
  }

  .modal-print{
    margin: 0;
    max-width: unset;
    border-radius: 0;
    height: 100vh;
    max-height: unset;
  }

  .modal-sac{
    margin: 0;
    max-width: unset;
    border-radius: 0;
    height: 100vh;
    max-height: unset;
  }

  .modal-new-doc{
    margin: 0;
    max-width: unset;
    border-radius: 0;
    height: 100vh;
    max-height: unset;
  }

  .modal-view-doc{
    margin: 0;
    max-width: unset;
    border-radius: 0;
    height: 100vh;
    max-height: unset;
  }

  .modal-deadline{
    margin: 0;
    max-width: unset;
    border-radius: 0;
    max-height: 100vh;
    background-color: #FFF;
  }
}

@media (max-width: 575px) {
  .modal-notification {
    margin: 20px auto !important;
  }

  .modal-sound {
    margin: 20px auto !important;
  }

  .modal-warn-print {
    margin: 20px auto !important;
  }

  .modal-warn-timer{
    margin: 20px auto !important;
  }

  .delete-complement-modal{
    margin: 20px auto !important;

  }
}

@media (max-width: 480px) {
  .modal-warn-timer{
    height: 100vh;
    background: transparent;
    max-width: unset !important;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 0 !important;
  }
}

@media(max-width: 445px){
  .modal-warn-print {
      margin: 20px 15px !important;
  }

  .modal-sound{
    margin: 20px 15px !important;
  }

  .delete-complement-modal{
    margin: 20px 15px !important;
  }
}

@media (max-width: 440px) {
  .modal-notification {
    margin: 0 !important;
    max-width: unset;
    height: 100vh;
    border-radius: 0;
  }
}

.jv-n{
  jdiv{
    display: none
  }
}

// SKELETON HOME STYLE

.skeleton{
  &.home{
    ngx-skeleton-loader{
      display: flex;
      height: 100%;
      width: 100%;
      span{
        margin: 0
      }
    }
  }
}

// ADJUSTMENTS CETRAL ORDER

@media(max-width: 480px){
  .central-orders{
    .central-order__wrapper{
      .orders-radius{
        border-radius: 16px 16px 0 0 !important;
      }
    }
    .menu__mobile{
      svg{
        fill: #FFF !important;
        &:hover{
          fill: #C1F4E6 !important;
        }
      }
    }
    &.noorders{
      .menu__mobile{
        svg{
          fill: #000 !important;
          &:hover{
            fill: #05CB95 !important;
          }
        }
      }
    }
  }
}

// ADJUSTMENTS PIX PAGE

#pixkey{
  & ~ typeahead-container{
    max-width: 148px !important;
  }
}

@media(max-width: 480px){
  #pixkey{
    & ~ typeahead-container{
      max-width: unset !important;
    }
  }
}

@import "styles/styles.scss";
