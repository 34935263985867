.content-body {
    margin-top: 32px;
    margin-bottom: 24px;
    padding-left: 16px;
    padding-right: 16px;
    @include breakpoint('tablet') {
        margin-top: 18px;
        padding: 0;
    }
}
.title-page {
    padding-left: 16px;
    font-size: 32px;
    font-weight: 500;
    color: $color-soft-dark;
    @include breakpoint('tablet') {
        padding: 0;
    }
}
.breadcrumb {
    color: $color-gray-medium;
    padding-left: 16px;
    padding-right: 16px;
    font-size: .813rem;
    margin-bottom: 0;
    background-color: initial;
}
a.breadcrumb:hover {
    color: $color-soft-dark;
    text-decoration: none;
}
