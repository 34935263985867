.form-field-group {
  display: flex;
  flex-direction: column;
  justify-content: start;
  label {
    font-weight: 500;
    font-size: 18px;
    &[required="true"]::after {
      content: " *";
      color: #EB3941;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 0;
    }
  }
  *:not(label) {
    display: flex;
    align-items: center;
    flex: 1;
    // align-self: center;
  }
}
