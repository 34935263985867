.buttons {
	font-size: 16px;
	color: #fff;
	border: none;
	padding-left: 70px;
	padding-right: 70px;
	height: 45px;
	width: 100%;
}
.button--success {
	background-color: $primary-color
}
.button--rounded {
	border-radius: 30px;
}

button {
  font-size: 16px;
	color: #fff;
	border: none;
	height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary-color;
  border-radius: 30px;
  height: 50px;

  &:active:not(:disabled) {
    outline: 3px solid rgba($primary-color, 0.2);
  }

  &:hover {
    color: #fff;
  }

  &:disabled {
    background-color: #F5F5F5;
    color: $color-gray-light;
    cursor: not-allowed;
  }
}
